import styled from 'styled-components'

const PanelActions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 0.9375rem;
  width: auto;
  height: 100%;
`

export default PanelActions
