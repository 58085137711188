import React from 'react'
import styled from 'styled-components'

import {
  Button,
  IconDelete,
  ModalActions,
  ModalContent,
  ModalHeader,
} from 'ui/components'

interface Props {
  onCancel?: () => void
  onDelete?: () => void
}

const StyledIcon = styled(IconDelete)`
  margin-bottom: 2.1875rem;
`

const StyledButton = styled(Button)`
  width: 100%;
`

const ModalSessionDelete: React.FC<Props> = ({ onCancel, onDelete }) => {
  function handleDelete() {
    onDelete && onDelete()
  }

  return (
    <ModalContent>
      <StyledIcon />
      <ModalHeader
        title="Delete session?"
        subtitle="Once a session is deleted, you can no longer access it."
      />

      <ModalActions>
        <StyledButton
          type="button"
          bgType="border"
          size="default"
          color="primary"
          label="Cancel"
          onClick={onCancel}
        />
        <StyledButton
          type="button"
          bgType="fill"
          size="default"
          color="primary"
          label="Delete"
          onClick={handleDelete}
        />
      </ModalActions>
    </ModalContent>
  )
}

export default ModalSessionDelete
