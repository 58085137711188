import React from 'react'
import styled from 'styled-components'

import { Icon } from '../Icon'
import theme from 'ui/theme'

interface SearchButtonProps {
  onClick?: () => void
  className?: any
}

const Layout = styled.button`
  appearance: none;
  display: flex;
  position: absolute;
  top: 0.6875rem;
  right: 0.6875rem;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: ${theme.button.size.icon / 16}rem;
  height: ${theme.button.size.icon / 16}rem;
  border-radius: ${theme.button.size.icon / 16 / 2}rem;
  border: none;
  outline: none;
  background-color: ${theme.color.primary};
  cursor: pointer;
`

const SearchButton: React.FC<SearchButtonProps> = ({ onClick, className }) => {
  return (
    <Layout type="button" onClick={onClick} className={className}>
      <Icon icon="Search" fill={theme.color.white} width={20} height={20} />
    </Layout>
  )
}

export default SearchButton
