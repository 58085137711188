import styled from 'styled-components'

import Text from './Text'

const Instructions = styled(Text)`
  line-height: 135%;
  letter-spacing: -0.1px;
  text-align: center;
  opacity: 0.65;
`

export default Instructions
