import React, { useState } from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'

import {
  Button,
  PanelActions,
  PanelContent,
  PanelFooter,
  PanelHeader,
  PanelLayout,
  UserList,
} from 'ui/components'
import { User } from 'context/SessionData'

interface Props {
  isActive?: boolean
  onBack?: () => void
  onInvite?: (users: User[]) => void
}

const PanelInvite: React.FC<Props> = ({
  isActive = false,
  onBack,
  onInvite,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<User[]>()

  return (
    <Transition
      items={isActive}
      from={{ position: 'absolute', left: '100%', opacity: 0 }}
      enter={{ left: '0%', opacity: 1 }}
      leave={{ left: '100%', opacity: 0 }}
      config={config.stiff}
    >
      {show =>
        show &&
        (props => (
          <PanelLayout style={props}>
            <PanelHeader type="back" title="Invite Users" onBack={onBack} />

            <PanelContent>
              <UserList
                selectedUserIdentifiers={selectedUsers}
                setSelectedUserIdentifieres={setSelectedUsers}
              />
            </PanelContent>

            <PanelFooter>
              <PanelActions>
                <Button
                  type="button"
                  bgType="fill"
                  size="default"
                  label="Invite users"
                  onClick={() => onInvite && onInvite(selectedUsers || [])}
                />
              </PanelActions>
            </PanelFooter>
          </PanelLayout>
        ))
      }
    </Transition>
  )
}

export default PanelInvite
