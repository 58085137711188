import React from 'react'
import styled from 'styled-components'
import theme from 'ui/theme'

import LogoColor from 'ui/assets/image/qrs_logo-color.png'
import LogoWhite from 'ui/assets/image/qrs_logo-white.png'

interface LogoProps {
  type?: 'color' | 'white'
  className?: any
}

const Layout = styled.img`
  display: inline-flex;
  width: 9.375rem;
  height: auto;
`

const Logo: React.FC<LogoProps> = ({ type = 'color', className }) => {
  return (
    <Layout
      className={className}
      src={type === 'color' ? LogoColor : LogoWhite}
      alt={theme.appName}
    />
  )
}

export default Logo
