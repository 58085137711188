import React from 'react'
import styled from 'styled-components'

import { Select } from '../Input'
import theme from 'ui/theme'

interface FilterProps {
  label?: string
  children?: React.ReactNode
  className?: any
}

const Layout = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    display: grid;
  }
  display: none;
  grid-template-columns: repeat(2, auto);
  grid-gap: 0.625rem;
  align-items: center;
  width: auto;
  height: ${theme.input.height / 16}rem;
  margin-right: 1.5625rem;
`

const Label = styled.label`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 100%;
  text-align: right;
  color: ${theme.color.text.default};
  opacity: 0.55;
`

const StyledSelect = styled(Select)`
  width: 12.5rem;
`

const Filter: React.FC<FilterProps> = ({ label, children, className }) => {
  return (
    <Layout className={className}>
      {label && <Label>{label}</Label>}
      <StyledSelect>{children}</StyledSelect>
    </Layout>
  )
}

export default Filter
