import React from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import {
  Button,
  Metric,
  PanelActions,
  PanelContent,
  PanelFooter,
  PanelHeader,
  PanelLayout,
  TextArea,
} from 'ui/components'
import theme from 'ui/theme'

import MIDIFPO from 'ui/assets/image/MIDI.png'

interface Props {
  isActive?: boolean
  sessionMidiMap?: any
  sessionDevice?: any
  sessionTotalTime?: any
  sessionNotesPlayed?: string
  onDownloadMIDI?: () => void
  onShare?: () => void
}

const MIDIViz = styled.section`
  width: 100%;
  height: 11.5625rem;
  margin-bottom: 1.25rem;
  background-color: ${theme.color.white};
`

const MIDI = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ContentContainer = styled.div`
  width: 100%;
  padding: 0 1.5625rem;
`

const SessionMetrics = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5625rem 1.5625rem;
  width: 100%;
  margin-bottom: 3rem;
`

const DeviceName = styled(Metric)`
  grid-column: 1/3;
`

const Message = styled(TextArea)``

const PanelCompleteSummary: React.FC<Props> = ({
  isActive = false,
  sessionMidiMap,
  sessionDevice,
  sessionNotesPlayed,
  sessionTotalTime,
  onDownloadMIDI,
  onShare,
}) => {
  return (
    <Transition
      items={isActive}
      from={{ position: 'absolute', left: '-100%', opacity: 0 }}
      enter={{ left: '0%', opacity: 1 }}
      leave={{ left: '-100%', opacity: 0 }}
      config={config.stiff}
    >
      {show =>
        show &&
        (props => (
          <PanelLayout style={props}>
            <PanelHeader type="default" title={`Share to ${theme.appName}`} />

            <PanelContent fullWidth>
              <MIDIViz>
                {sessionMidiMap ? (
                  sessionMidiMap
                ) : (
                  <MIDI src={MIDIFPO} alt="MIDI" />
                )}
              </MIDIViz>

              <ContentContainer>
                <SessionMetrics>
                  <DeviceName
                    size="small"
                    color="dark"
                    label="Device"
                    value={sessionDevice}
                  />
                  <Metric
                    size="small"
                    color="dark"
                    label="Total Time"
                    value={sessionTotalTime}
                  />
                  <Metric
                    size="small"
                    color="dark"
                    label="Notes Played"
                    value={sessionNotesPlayed}
                  />
                </SessionMetrics>

                <Message placeholder="Write a message here (optional)." />
              </ContentContainer>
            </PanelContent>

            <PanelFooter>
              <PanelActions>
                <Button
                  type="button"
                  bgType="border"
                  size="default"
                  color="primary"
                  label="MIDI File"
                  onClick={onDownloadMIDI}
                />
                <Button
                  type="button"
                  bgType="fill"
                  size="default"
                  color="primary"
                  label="Share"
                  onClick={onShare}
                />
              </PanelActions>
            </PanelFooter>
          </PanelLayout>
        ))
      }
    </Transition>
  )
}

export default PanelCompleteSummary
