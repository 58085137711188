import React from 'react'
import styled, { keyframes } from 'styled-components'

interface LoaderProps {
  size?: number
  strokeWidth?: number
  duration?: number
  className?: any
}

const spinAnimation = keyframes`
  0% {
      transform: rotate(0deg);
    }
  100% {
    transform: rotate(360deg);
  }
`

const getStrokeWidth = ({ strokeWidth }: LoaderProps) => {
  if (strokeWidth) {
    return `${strokeWidth / 16}rem`
  }
  return '.21875rem'
}

const STAGE_COLOR = 'rgba(255, 255, 255, .25)'
const SPIN_COLOR = 'white'

const Layout = styled.div`
  position: relative;
  width: ${({ size }: LoaderProps) => (size ? `${size / 16}rem` : '1.25rem')};
  height: ${({ size }: LoaderProps) => (size ? `${size / 16}rem` : '1.25rem')};
  border-radius: 50%;
  border-top: ${getStrokeWidth} solid ${STAGE_COLOR};
  border-right: ${getStrokeWidth} solid ${STAGE_COLOR};
  border-bottom: ${getStrokeWidth} solid ${STAGE_COLOR};
  border-left: ${getStrokeWidth} solid ${SPIN_COLOR};
  animation: ${spinAnimation}
    ${({ duration }: LoaderProps) => (duration ? `${duration}ms` : '1000ms')}
    infinite linear;
`

const Loader: React.FC<LoaderProps> = ({
  size = 20,
  strokeWidth = 3.5,
  duration = 1000,
  className,
}) => {
  return (
    <Layout
      size={size}
      strokeWidth={strokeWidth}
      duration={duration}
      className={className}
    />
  )
}

export default Loader
