import React, { useState } from 'react'

import PanelCompleteDownload from './PanelCompleteDownload'
import PanelCompleteShare from './PanelCompleteShare'
import PanelCompleteSummary from './PanelCompleteSummary'

interface Props {
  sessionMidiMap?: any
  sessionDevice?: string
  sessionTotalTime?: any
  sessionNotesPlayed?: string
  onBack?: () => void
  onDownloadMIDI?: () => void
  onShare?: () => void
}

type SessionCompletePanel = 'sessionSummary' | 'shareSuccess' | 'download'

const PanelComplete: React.FC<Props> = ({
  sessionMidiMap,
  sessionDevice,
  sessionTotalTime,
  sessionNotesPlayed,
  onDownloadMIDI,
  onShare,
}) => {
  const [activePanel, setActivePanel] = useState<SessionCompletePanel>(
    'sessionSummary',
  )

  function downloadSession() {
    setActivePanel('download')
    onDownloadMIDI && onDownloadMIDI()
  }

  function shareSession() {
    setActivePanel('shareSuccess')
    onShare && onShare()
  }

  return (
    <>
      <PanelCompleteSummary
        isActive={activePanel === 'sessionSummary'}
        sessionMidiMap={sessionMidiMap}
        sessionDevice={sessionDevice}
        sessionTotalTime={sessionTotalTime}
        sessionNotesPlayed={sessionNotesPlayed}
        onDownloadMIDI={downloadSession}
        onShare={shareSession}
      />

      <PanelCompleteShare
        isActive={activePanel === 'shareSuccess'}
        onBack={() => setActivePanel('sessionSummary')}
      />

      <PanelCompleteDownload
        isActive={activePanel === 'download'}
        onBack={() => setActivePanel('sessionSummary')}
      />
    </>
  )
}

export default PanelComplete
