import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import theme from 'ui/theme'
import { Icon } from 'ui/components/Icon'
// TODO TS throws error 2709 when importing types
// import { IconTypes } from 'ui/components/Icon'

interface ButtonIconProps {
  icon: any // TODO use IconTypes, TS throws error 2709
  fill?: any
  to?: any
  onClick?: () => void
  className?: any
}

const Layout = styled.button`
  appearance: none;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${theme.button.size.icon / 16}rem;
  height: ${theme.button.size.icon / 16}rem;
  border-radius: ${theme.button.size.icon / 16 / 2}rem;
  border: none;
  outline: none;
  background-color: ${theme.color.primary};
  overflow: hidden;
  cursor: pointer;
  transition: background-color 50ms ease-in;
`

const ButtonIcon: React.FC<ButtonIconProps> = ({
  icon,
  fill = theme.color.white,
  to,
  onClick,
  className,
}) => {
  return (
    <>
      {to ? (
        <Link to={to}>
          <Layout type="button" onClick={onClick} className={className}>
            <Icon icon={icon} fill={fill} width={22} height={22} />
          </Layout>
        </Link>
      ) : (
        <Layout type="button" onClick={onClick} className={className}>
          <Icon icon={icon} fill={fill} width={22} height={22} />
        </Layout>
      )}
    </>
  )
}

export default ButtonIcon
