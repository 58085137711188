import styled from 'styled-components'
import theme from 'ui/theme'

const TextInput = styled.textarea`
  appearance: none;
  width: 100%;
  height: 9.375rem;
  padding: 1.5rem 1.5rem;
  border-radius: 1.5rem;
  border-width: ${theme.ui.border / 16}rem;
  border-style: solid;
  border-color: transparent;
  outline: none;
  background-color: #eff0f0;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  color: ${theme.color.text};
  transition: border-color 150ms ease-in;
  resize: none;

  &:focus {
    border-color: ${theme.validation.focus};
  }

  &::placeholder {
    opacity: 0.55;
  }
`

export default TextInput
