import React, { useEffect, useRef } from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import {
  ButtonIcon,
  PanelContent,
  PanelHeader,
  PanelLayout,
  SessionParticipant,
} from 'ui/components'
import { SessionScheduleItem } from 'context/SessionData'
import { useAuth } from 'context/AuthenticationContext'

import UserImg from 'ui/assets/image/user.png'

interface Props {
  sessionScheduleItem?: SessionScheduleItem
  isActive?: boolean
  onAdd?: () => void
}

const ParticipantsList = styled.ul`
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  list-style: none;
`

const PanelParticipants: React.FC<Props> = ({
  isActive = false,
  onAdd,
  sessionScheduleItem,
}) => {
  var participantsList = useRef<JSX.Element[]>()

  const { userIdentifier } = useAuth()

  useEffect(() => {
    if (sessionScheduleItem !== undefined) {
      participantsList.current =
        sessionScheduleItem.attendees && //This use to be sessionScheduleItem.invitees
        sessionScheduleItem.attendees.map(value => {
          return (
            <SessionParticipant
              key={value.userIdentifier}
              image={UserImg}
              name={value.fullName}
              isActive={
                sessionScheduleItem.performed_by &&
                sessionScheduleItem.performed_by.includes(value)
              }
            />
          )
        })
    }
  }, [sessionScheduleItem, participantsList])

  return (
    <Transition
      items={isActive}
      from={{ position: 'absolute', left: '-100%', opacity: 0 }}
      enter={{ left: '0%', opacity: 1 }}
      leave={{ left: '-100%', opacity: 0 }}
      config={config.stiff}
    >
      {show =>
        show &&
        (props => (
          <PanelLayout style={props}>
            <PanelHeader type="default" title="Participants">
              {sessionScheduleItem &&
                sessionScheduleItem.performed_by &&
                sessionScheduleItem.performed_by.filter(value => {
                  return value.userIdentifier === userIdentifier
                }).length >= 1 && <ButtonIcon icon="Add" onClick={onAdd} />}
            </PanelHeader>

            <PanelContent>
              <ParticipantsList>{participantsList.current}</ParticipantsList>
            </PanelContent>
          </PanelLayout>
        ))
      }
    </Transition>
  )
}

export default PanelParticipants
