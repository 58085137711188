import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import theme from 'ui/theme'

interface HeaderNavItemProps {
  type?: 'default' | 'download'
  label?: string
  to?: any
  className?: any
}

const Link = styled(NavLink)`
  color: ${theme.color.text.default};

  &.navActive {
    color: ${theme.color.primary};
  }
`

const DownloadLink = styled.a`
  color: ${theme.color.text.default};

  &.navActive {
    color: ${theme.color.primary};
  }
`

const Layout = styled.span`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 3rem;
  margin-bottom: 0.9375rem;
  padding: 0;
  text-align: left;
  color: inherit;
  cursor: pointer;
`

const Label = styled.span`
  font-family: 'Montserrat';
  font-weight: 300;
  font-size: 1.5625rem;
  line-height: 100%;
  letter-spacing: 0.25px;
  color: inherit;
`

const HeaderNavItem: React.FC<HeaderNavItemProps> = ({
  type = 'default',
  label,
  to,
  className,
}) => {
  return type === 'download' ? (
    <DownloadLink href={to} target="_blank">
      <Layout>
        <Label>{label}</Label>
      </Layout>
    </DownloadLink>
  ) : (
    <Link to={to} className={className} activeClassName="navActive">
      <Layout>
        <Label>{label}</Label>
      </Layout>
    </Link>
  )
}

export default HeaderNavItem
