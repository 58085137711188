import React from 'react'
import { Spring } from 'react-spring/renderprops'
import styled from 'styled-components'

import theme from 'ui/theme'

interface HeaderNavProps {
  isActive?: boolean
  children?: React.ReactNode
  className?: any
}

const Layout = styled.nav`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    width: 21.875rem;
  }

  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 501;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 18.75rem;
  height: 100%;
  padding: 3.125rem;
  border-radius: 0 ${theme.ui.radius / 16}rem ${theme.ui.radius / 16}rem 0;
  background-color: white;
`

const HeaderNav: React.FC<HeaderNavProps> = ({
  isActive = false,
  children,
  className,
}) => {
  return (
    <Spring
      from={{ left: '-100%' }}
      to={{ left: isActive ? '0%' : '-100%' }}
      config={{ mass: 1, tension: 170, friction: 26 }}
    >
      {props => (
        <Layout role="navigation" className={className} style={props}>
          {children}
        </Layout>
      )}
    </Spring>
  )
}

export default HeaderNav
