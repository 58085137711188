import styled from 'styled-components'

const ProfileActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  height: auto;
`

export default ProfileActions
