import React, { useState } from 'react'
import styled from 'styled-components'

import {
  AuthInstructions,
  AuthLogo,
  AuthPanel,
  AuthScreenContainer,
  Button,
  IconMusicNote,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  H1,
} from 'ui/components'
import theme from 'ui/theme'

import './demo.css'

import BGImg from 'ui/assets/image/qrs_bg_device-gray.jpg'

import { AuthCreateAccountForm } from '../Auth/forms'

const Walkthrough = styled.section`
  grid-area: ${theme.ui.layout.content};
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const MusicNote = styled(IconMusicNote)`
  margin-bottom: 2.1875rem;
`

const StyledModalActions = styled(ModalActions)`
  grid-template-columns: auto;
  justify-items: center;
`

const StyledH1 = styled(H1)`
  font-size: 3.375rem !important;
  color: ${theme.color.white};
  text-align: center;
  margin-bottom: 50px;
`

const DemoCreateAccountScreen: React.FC = () => {
  const [modalActive, setModalActive] = useState<boolean>(false)

  const handleSubmit = () => {
    // TODO: validation
    setModalActive(true)
  }

  const handleReset = () => {
    // TODO: clear the form on reset
    setModalActive(!modalActive)
  }

  return (
    <AuthScreenContainer bgImage={BGImg}>
      <Walkthrough>
        <StyledH1>Sign-up Here!</StyledH1>
        <StyledH1>For a chance to win a QRS Connector.</StyledH1>
      </Walkthrough>

      <AuthPanel
        innerStyle={{
          justifyContent: 'center',
        }}
      >
        <AuthLogo />
        <AuthInstructions>
          Sign up to be invited to {theme.appName}.
        </AuthInstructions>

        <AuthCreateAccountForm handlePreRegistration={handleSubmit} />
      </AuthPanel>

      <Modal isActive={modalActive} toggleModal={handleReset}>
        <ModalContent>
          <MusicNote />

          <ModalHeader
            title={`Thank you for your interest in ${theme.appName}!`}
            subtitle="You will be receiving an email from us shortly."
          />
          <StyledModalActions>
            <Button
              type="button"
              bgType="fill"
              size="default"
              color="primary"
              label="Thanks"
              onClick={handleReset}
            />
          </StyledModalActions>
        </ModalContent>
      </Modal>
    </AuthScreenContainer>
  )
}

export default DemoCreateAccountScreen
