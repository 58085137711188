import React, { useCallback, useState, useEffect } from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

import {
  Button,
  Icon,
  Notification,
  PanelActions,
  PanelContent,
  PanelFooter,
  PanelHeader,
  PanelLayout,
  SessionDetailsForm,
} from 'ui/components'
import theme from 'ui/theme'

import { useSession } from 'context/SessionContext'
import { useAuth } from 'context/AuthenticationContext'
import { SessionScheduleItem } from 'context/SessionData'

interface Props {
  isActive?: boolean
  onBack?: () => void
  onSave?: () => void
  onCancelEdit?: () => void
  onDelete?: () => void
  sessionScheduleItem?: SessionScheduleItem
}

const DeleteContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  cursor: pointer;
`

const PanelEditSession: React.FC<Props> = ({
  isActive = false,
  onBack,
  onSave,
  onCancelEdit,
  onDelete,
  sessionScheduleItem,
}) => {
  const [error, setErrorMessage] = useState<string>('')
  const setError = (error: string) => {
    setErrorMessage(error)
    setTimeout(() => {
      setErrorMessage('')
    }, 5000)
  }

  const clearError = () => {
    setErrorMessage('')
  }

  const { authorizationToken, userIdentifier } = useAuth()
  const { updateSession } = useSession()

  const [session, setSession] = useState<SessionScheduleItem>(
    sessionScheduleItem || {},
  )
  const [
    sessionDetailFormShouldTriggerValidation,
    setSessionDetailFormShouldTriggerValidation,
  ] = useState(false)
  const [sessionShouldSave, setSessionShouldSave] = useState(false)

  const sessionDetailFormValidationResult = useCallback(
    async (result: boolean) => {
      setSessionDetailFormShouldTriggerValidation(false)
      if (result === true) {
        setSessionShouldSave(true)
      }
    },
    [],
  )

  const performSave = () => {
    setSessionDetailFormShouldTriggerValidation(true)
  }

  useEffect(() => {
    const saveUpdateToService = async () => {
      if (authorizationToken && userIdentifier) {
        const result = await updateSession(
          authorizationToken,
          userIdentifier,
          session,
        )
        if (result.status) {
          onSave && onSave()
        } else {
          setError(
            'Unable to save Session Updates. Please refresh and try again.',
          )
        }
      }
    }

    if (isEmpty(session) && sessionScheduleItem !== undefined) {
      setSession(sessionScheduleItem)
    }

    if (sessionShouldSave === true) {
      setSessionShouldSave(false)
      saveUpdateToService()
    }
  }, [
    session,
    sessionScheduleItem,
    sessionShouldSave,
    updateSession,
    onSave,
    authorizationToken,
    userIdentifier,
  ])

  return (
    <>
      <Notification
        messageType="error"
        message={error}
        isActive={error !== ''}
        onDismiss={() => clearError()}
      />
      <Transition
        items={isActive}
        from={{ position: 'absolute', left: '100%', opacity: 0 }}
        enter={{ left: '0%', opacity: 1 }}
        leave={{ left: '100%', opacity: 0 }}
        config={config.stiff}
      >
        {show =>
          show &&
          (props => (
            <PanelLayout style={props}>
              <PanelHeader type="back" title="Edit Session" onBack={onBack}>
                <DeleteContainer onClick={onDelete}>
                  <Icon
                    icon="Delete"
                    width={20}
                    height={20}
                    fill={theme.color.primary}
                  />
                </DeleteContainer>
              </PanelHeader>

              <PanelContent>
                <SessionDetailsForm
                  setSession={setSession}
                  session={session}
                  sessionDetailFormShouldTriggerValidation={
                    sessionDetailFormShouldTriggerValidation
                  }
                  sessionDetailFormValidationResult={
                    sessionDetailFormValidationResult
                  }
                />
              </PanelContent>

              <PanelFooter>
                <PanelActions>
                  <Button
                    type="button"
                    bgType="border"
                    size="default"
                    color="primary"
                    label="Cancel"
                    onClick={onCancelEdit}
                  />
                  <Button
                    type="button"
                    bgType="fill"
                    size="default"
                    color="primary"
                    label="Save changes"
                    onClick={performSave}
                  />
                </PanelActions>
              </PanelFooter>
            </PanelLayout>
          ))
        }
      </Transition>
    </>
  )
}

export default PanelEditSession
