const QRS_RED = '#D51928'

const theme = {
  appName: 'QRS-Connect',

  button: {
    size: {
      circle: 48,
      default: 40,
      icon: 27,
      large: 48,
      small: 30,
    },
  },

  color: {
    background: 'rgb(31,31,31)',
    border: 'rgb(222, 222, 222)',
    primary: QRS_RED,
    secondary: 'rgb(255,255,255)',
    red: 'rgb(216,52,52)',
    text: {
      default: 'rgba(51,51,51)',
      light: 'rgba(51,51,51, .5)',
    },
    white: 'rgb(255,255,255)',
  },

  input: {
    height: 48,
  },

  modal: {
    overlay: 'rgba(0, 0, 0, 0.85)',
    zIndex: {
      container: 100,
      content: 101,
      navigation: 102,
    },
  },

  notification: {
    color: {
      alert: 'rgba(0,0,0,.85)',
      error: QRS_RED,
      success: '#2E9835',
    },
    zIndex: 200,
  },

  panel: {
    gutter: 25,
    footer: {
      height: '5.625rem',
    },
    layout: {
      content: 'panelContent',
      footer: 'panelFooter',
      header: 'panelHeader',
    },
    zIndex: {
      content: 0,
      footer: 0,
      header: 0,
    },
  },

  schedule: {
    item: {
      height: 115,
    },
  },

  ui: {
    border: 1.25,
    breakpoint: {
      medium: '768px',
      large: '1024px',
      xlarge: '1345px',
      xxLarge: '2000px',
    },
    gutter: {
      small: '1.5625rem',
    },
    layout: {
      header: 'appHeader',
      panel: 'appPanel',
      content: 'appContent',
    },
    radius: 20,
  },

  validation: {
    default: 'rgb(239,240,241)',
    error: 'rgb(216,52,52)',
    focus: 'rgb(204,204,204)',
  },
}

export default theme
