import React from 'react'
import styled from 'styled-components'

import {
  Button,
  InputText,
  Instructions,
  ProfileAvatar,
  ProfileContentContainer,
  ProfileHeader,
  ProfileTitle,
} from 'ui/components'

const StyledProfileContentContainer = styled(ProfileContentContainer)`
  justify-items: center;
`

const Layout = styled.div`
  max-width: 40.3125rem;
  width: 100%;
`

const Form = styled.form`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3.125rem;
  width: 100%;
  height: auto;
  margin-top: 3.125rem;
  padding-bottom: 3.125rem;
`

const Fieldset = styled.section`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1.25rem;
  width: 100%;
`

const FieldsetHeader = styled.header`
  width: 100%;
`

const HeaderText = styled(Instructions)`
  margin-bottom: 0.3125rem;
  font-weight: 600;
  text-align: left;
`

const AvatarContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2.5rem;
  align-items: center;
  width: 100%;
`

const ProfileSettings: React.FC = () => {
  return (
    <StyledProfileContentContainer>
      <Layout>
        <ProfileHeader>
          <ProfileTitle>Settings</ProfileTitle>
        </ProfileHeader>

        <Form>
          <Fieldset>
            <FieldsetHeader>
              <HeaderText>Avatar</HeaderText>
            </FieldsetHeader>

            <AvatarContainer>
              <ProfileAvatar />

              <Button
                type="button"
                bgType="border"
                size="small"
                color="primary"
                label="Upload New Image"
              />
            </AvatarContainer>
          </Fieldset>

          <Fieldset>
            <FieldsetHeader>
              <HeaderText>Personal Information</HeaderText>
            </FieldsetHeader>

            <InputText label="First Name" defaultValue="User" />
            <InputText label="Last Name" defaultValue="Name" />
            <InputText label="Email" defaultValue="user@name.com" />
            <Button
              type="submit"
              bgType="fill"
              size="default"
              color="primary"
              label="Save Changes"
            />
          </Fieldset>

          <Fieldset>
            <FieldsetHeader>
              <HeaderText>Password</HeaderText>
            </FieldsetHeader>

            <InputText label="New Password" placeholder="New Password" />
            <InputText
              label="Confirm New Password"
              placeholder="New Password"
            />
            <Button
              type="submit"
              bgType="fill"
              size="default"
              color="primary"
              label="Save Changes"
            />
          </Fieldset>
        </Form>
      </Layout>
    </StyledProfileContentContainer>
  )
}

export default ProfileSettings
