import React from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import theme from 'ui/theme'

interface ModalProps {
  toggleModal?: () => void
  isActive?: boolean
  children?: any
  className?: any
}

const Layout = styled.section`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${theme.modal.zIndex.container};
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: scroll;
`

const ModalBG = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${theme.modal.zIndex.container};
  width: 100%;
  height: 100%;
  background-color: ${theme.modal.overlay};
  cursor: pointer;
`

const Modal: React.FC<ModalProps> = ({
  isActive = false,
  toggleModal,
  children,
  className,
}) => {
  return (
    <Transition
      items={isActive}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      config={config.stiff}
    >
      {isActive =>
        isActive &&
        (props => (
          <Layout className={className} style={props}>
            {children}
            <ModalBG onClick={toggleModal} />
          </Layout>
        ))
      }
    </Transition>
  )
}

export default Modal
