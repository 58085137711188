import React from 'react'
import { config } from 'react-spring'
import { Spring } from 'react-spring/renderprops'
import styled from 'styled-components'

import theme from 'ui/theme'

interface ModalContentProps {
  children?: React.ReactNode
  classsName?: any
}

const Layout = styled.div`
  display: flex;
  position: relative;
  z-index: ${theme.modal.zIndex.content};
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 25.625rem;
  height: auto;
  padding: 2.5rem 2.5rem;
  border-radius: ${theme.ui.radius / 16}rem;
  background-color: ${theme.color.white};
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
  transition: height ease-in-out 150ms;
  overflow: hidden;
`

const ModalContent: React.FC<ModalContentProps> = ({
  children,
  classsName,
}) => {
  return (
    <Spring
      from={{ top: '-25%', opacity: 0 }}
      to={{ top: '0%', opacity: 1 }}
      config={config.stiff}
    >
      {props => (
        <Layout className={classsName} style={props}>
          {children}
        </Layout>
      )}
    </Spring>
  )
}

export default ModalContent
