import React from 'react'
import styled from 'styled-components'

import ProfileContent from './ProfileContent'
import theme from 'ui/theme'

interface ProfileDateSectionProps {
  label?: string
  children?: React.ReactNode
  className?: any
}

const Layout = styled.section``

const Header = styled.header`
  width: 100%;
  margin-bottom: 1.5625rem;
`

const Label = styled.span`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1rem;
  line-height: 100%;
  letter-spacing: 0.13px;
  color: ${theme.color.text.default};
  opacity: 0.75;
`

const ProfileDateSection: React.FC<ProfileDateSectionProps> = ({
  label,
  children,
  className,
}) => {
  return (
    <Layout className={className}>
      <Header>
        <Label>{label}</Label>
      </Header>

      <ProfileContent>{children}</ProfileContent>
    </Layout>
  )
}

export default ProfileDateSection
