import React, { useState, useCallback } from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import { usePrevious } from '../../../hooks'

import {
  Button,
  Notification,
  PanelActions,
  PanelContent,
  PanelFooter,
  PanelHeader,
  PanelLayout,
  ScheduleGroup,
  ScheduleItem,
  SessionDetailsForm,
  UserList,
} from 'ui/components'

import { useSession } from 'context/SessionContext'
import { useAuth } from 'context/AuthenticationContext'
import { SessionScheduleItem, User } from 'context/SessionData'
import moment from 'moment'

moment.updateLocale('en', {
  relativeTime: {
    h: '1 hour',
    d: '1 day',
  },
})

moment.relativeTimeThreshold('s', 59)
moment.relativeTimeThreshold('m', 59)
moment.relativeTimeThreshold('h', 24)
moment.relativeTimeThreshold('M', 12)

interface PanelCreateNewSessionProps {
  onBack: () => void
  onCancel?: () => void
  style?: any
}

type PanelSteps = 'details' | 'invite' | 'confirmation'

const Layout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const PanelCreateNewSession: React.FC<PanelCreateNewSessionProps> = ({
  onBack,
  onCancel,
  style,
}) => {
  const [error, setErrorMessage] = useState<string>('')
  const setError = (error: string) => {
    setErrorMessage(error)
    setTimeout(() => {
      setErrorMessage('')
    }, 5000)
  }

  const clearError = () => {
    setErrorMessage('')
  }

  const { authorizationToken, userIdentifier, fullName } = useAuth()
  const { createSession } = useSession()

  const [step, setStep] = useState<PanelSteps>('details')
  const previousStep = usePrevious(step)

  const [session, setSession] = useState<SessionScheduleItem>({})

  const [
    sessionDetailFormShouldTriggerValidation,
    setSessionDetailFormShouldTriggerValidation,
  ] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState<User[]>()

  const sessionDetailFormValidationResult = useCallback(
    async (result: boolean) => {
      setSessionDetailFormShouldTriggerValidation(false)
      if (result) {
        setStep('invite')
      }
    },
    [],
  )

  const saveSession = async () => {
    if (authorizationToken && userIdentifier) {
      const saveSession = session
      saveSession.invitees = selectedUsers
      const result = await createSession(
        authorizationToken,
        userIdentifier,
        saveSession,
      )

      if (result.status) {
        session.id = result.identifier
        setStep('confirmation')
      } else {
        setError('Unable to create a new session. Try again later.')
      }
    }
  }

  const nextStep = () => {
    if (step === 'details') {
      setSessionDetailFormShouldTriggerValidation(true)
    }
    if (step === 'invite') {
      saveSession()
    }
  }

  const prevStep = () => {
    if (step === 'invite') {
      setStep('details')
    }
    if (step === 'confirmation') {
      setStep('invite')
    }
  }

  const back = () => {
    if (step === 'details' || step === 'confirmation') {
      return onBack()
    }
    return prevStep()
  }

  const done = () => {
    onBack()
  }

  const confirmationDetail = (
    <ScheduleGroup label={moment(session.start_at).format('MMMM Do YYYY')}>
      <ScheduleItem
        sessionName={session.session_name}
        sessionOrganizer={fullName}
        sessionLength={moment
          .duration(moment(session.end_at).diff(moment(session.start_at)))
          .humanize()}
        sessionTime={moment(session.start_at).format('h:mm a')}
        numberOfParticipants={(selectedUsers || []).length}
        showTimeline={false}
        to={{ pathname: `/session/${session.id}` }}
      />
    </ScheduleGroup>
  )

  return (
    <>
      <Notification
        messageType="error"
        message={error}
        isActive={error !== ''}
        onDismiss={() => clearError()}
      />
      <Layout style={style}>
        <Transition
          items={step === 'details'}
          from={{
            position: 'absolute',
            left: previousStep === 'invite' ? '-100%' : '100%',
          }}
          enter={{ left: '0%' }}
          leave={{ left: '-100%' }}
          config={config.stiff}
        >
          {show =>
            show &&
            (props => (
              <PanelLayout style={props}>
                <PanelHeader
                  type="back"
                  title="Create New Session"
                  subtitle="Session Details"
                  onBack={back}
                />

                <PanelContent hasFooter>
                  <SessionDetailsForm
                    setSession={setSession}
                    session={session}
                    sessionDetailFormShouldTriggerValidation={
                      sessionDetailFormShouldTriggerValidation
                    }
                    sessionDetailFormValidationResult={
                      sessionDetailFormValidationResult
                    }
                  />
                </PanelContent>

                <PanelFooter>
                  <PanelActions>
                    <Button
                      type="button"
                      bgType="border"
                      size="default"
                      color="primary"
                      label="Cancel"
                      onClick={onCancel}
                    />

                    <Button
                      type="button"
                      bgType="fill"
                      size="default"
                      color="primary"
                      label="Next"
                      onClick={nextStep}
                    />
                  </PanelActions>
                </PanelFooter>
              </PanelLayout>
            ))
          }
        </Transition>

        <Transition
          items={step === 'invite'}
          from={{
            position: 'absolute',
            left: previousStep === 'details' ? '100%' : '-100%',
          }}
          enter={{ left: '0%' }}
          leave={{
            left: previousStep === 'details' ? '100%' : '-100%',
          }}
          config={config.stiff}
        >
          {show =>
            show &&
            (props => (
              <PanelLayout style={props}>
                <PanelHeader
                  type="back"
                  title="Create New Session"
                  subtitle="Invite Users"
                  onBack={back}
                />

                <PanelContent hasFooter>
                  <UserList
                    selectedUserIdentifiers={selectedUsers}
                    setSelectedUserIdentifieres={setSelectedUsers}
                  />
                </PanelContent>

                <PanelFooter>
                  <PanelActions>
                    <Button
                      type="button"
                      bgType="border"
                      size="default"
                      color="primary"
                      label="Cancel"
                      onClick={onCancel}
                    />

                    <Button
                      type="button"
                      bgType="fill"
                      size="default"
                      color="primary"
                      label="Next"
                      onClick={nextStep}
                    />
                  </PanelActions>
                </PanelFooter>
              </PanelLayout>
            ))
          }
        </Transition>

        <Transition
          items={step === 'confirmation'}
          from={{ position: 'absolute', left: '100%' }}
          enter={{ left: '0%' }}
          // TODO leave value needs to be '100%' if next state !== previousStep
          // might need to use numbered steps to accomplish this instead of named steps
          leave={{ left: '-100%' }}
          config={config.stiff}
        >
          {show =>
            show &&
            (props => (
              <PanelLayout style={props}>
                <PanelHeader
                  type="default"
                  title="New Session Created"
                  onBack={back}
                />

                <PanelContent fullWidth hasFooter>
                  {confirmationDetail}
                </PanelContent>

                <PanelFooter>
                  <PanelActions>
                    <Button
                      type="button"
                      bgType="fill"
                      size="default"
                      color="primary"
                      label="Done"
                      onClick={done}
                    />
                  </PanelActions>
                </PanelFooter>
              </PanelLayout>
            ))
          }
        </Transition>
      </Layout>
    </>
  )
}

export default PanelCreateNewSession
