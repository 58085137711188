import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Icon } from 'ui/components/Icon'
import theme from 'ui/theme'

interface ButtonBackProps {
  label?: string
  to?: any
  className?: any
  style?: any
}

const Layout = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  color: ${theme.color.white};
`

const Label = styled.span`
  display: flex;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: 0.13px;
`
const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  margin-right: 1.25rem;
  border-radius: 1.5rem;
  background-color: ${theme.color.white};
`

const ButtonBack: React.FC<ButtonBackProps> = ({
  label,
  to,
  className,
  style,
}) => {
  return (
    <Layout to={to} className={className} style={style}>
      <Arrow>
        <Icon
          icon="NavBack"
          fill={theme.color.background}
          width={35.5}
          height={35.5}
        />
      </Arrow>

      <Label>{label}</Label>
    </Layout>
  )
}

export default ButtonBack
