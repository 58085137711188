import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  RegistrationResponse,
  LoginResponse,
  AuthenticationError,
} from './AuthenticationData'

export interface AuthenticationProviderProperties {
  children?: React.ReactNode
}

export interface AuthenticationData {
  authorizationToken: string
}

export interface AuthenticationProviderState {
  login: (email: string, password: string) => Promise<LoginResponse>
  registerAccount: (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmationPassword: string,
    generatePassword: boolean,
  ) => Promise<RegistrationResponse>
  logout: () => void
  authorizationToken: string | undefined
  fullName: string | undefined
  userIdentifier: string | undefined
  isAuthenticating: boolean
}

export const AuthenticationContext = React.createContext<
  AuthenticationProviderState
>({
  login(_email: string, _password: string) {
    throw new Error('Initilization Error - Context is not initialized')
  },
  registerAccount(
    _firstName: string,
    _lastName: string,
    _email: string,
    _password: string,
    _confirmationPassword: string,
    _generatePassword: boolean,
  ) {
    throw new Error('Initilization Error - Context is not initialized')
  },
  logout() {
    throw new Error('Initilization Error - Context is not initialized')
  },
  authorizationToken: '',
  fullName: '',
  userIdentifier: '',
  isAuthenticating: false,
})
AuthenticationContext.displayName = 'AuthenticationContext'

const ENDPOINT_URL =
  process.env.REACT_APP_QRS_CONNECT_API_HOST_NAME || 'http://localhost:4000'

export function AuthenticationProvider(
  props: AuthenticationProviderProperties,
) {
  const [authorizationToken, setauthorizationToken] = useState(
    localStorage.getItem('authorizationToken') || undefined,
  )
  const [fullName, setFullName] = useState(
    localStorage.getItem('fullName') || undefined,
  )
  const [userIdentifier, setUserIdentifier] = useState(
    localStorage.getItem('userIdentifier') || undefined,
  )

  const [isAuthenticating, setIsAuthenticating] = useState(false)

  const processError = (error: any) => {
    let errors: AuthenticationError[] = []
    const entries: [string, [string]][] = Object.entries(error)
    if (entries.length > 0) {
      for (const [key, keyErrors] of entries) {
        errors.push({ fieldName: key, errors: keyErrors })
      }
    } else {
      errors.push({ fieldName: 'general', errors: ['Unexpected Error.'] })
    }
    return errors
  }

  const login = useCallback(async (username: string, password: string) => {
    let loginResponse: LoginResponse = {
      success: false,
    }

    try {
      setIsAuthenticating(true)
      const response = await fetch(`${ENDPOINT_URL}/api/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: username,
          password,
        }),
      })

      setIsAuthenticating(false)

      if (response.status.toString().match('^(2.{2})$')) {
        const result = await response.json()
        if (result.data.token) {
          setauthorizationToken(result.data.token)
          localStorage.setItem('authorizationToken', result.data.token)
          loginResponse = {
            success: true,
          }

          /* Start Profile Hack -- Until there is an endpoint available for this users identifier, i'm going to take their email address and call the list users endpoint and then get their identifier on that, there is a TODO item on the service side that will eventually provide this to me in a more appropriate way.*/
          const profileResponse = await fetch(`${ENDPOINT_URL}/api/v1/users`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: result.data.token,
            },
          })

          if (profileResponse.status.toString().match('^(2.{2})$')) {
            const profileResult = await profileResponse.json()
            if (Array.isArray(profileResult.data)) {
              const currentUser = profileResult.data.filter((value: any) => {
                return value.email === username
              })[0]

              setFullName(currentUser.full_name)
              localStorage.setItem('fullName', currentUser.full_name)

              setUserIdentifier(currentUser.id)
              localStorage.setItem('userIdentifier', currentUser.id)
            }
          } else {
            throw Error('Unable to login at this time. Profile unavailable.')
          }
          /* End Hack */
        } else {
          throw Error('Unable to login at this time. Unexpected login result.')
        }
      } else if (response.status.toString().match('^(4.{2})$')) {
        loginResponse = {
          error: [
            {
              fieldName: 'general',
              errors: ['Invalid credentials. Please try again.'],
            },
          ],
          success: false,
        }
      } else {
        throw Error(
          `Server responded with unexpected status code: ${response.status} `,
        )
      }
    } catch (error) {
      console.error(error)
      loginResponse = {
        error: [
          { fieldName: 'general', errors: ['Unable to login at this time.'] },
        ],
        success: false,
      }
      setIsAuthenticating(false)
    } finally {
      return loginResponse
    }
  }, [])

  const registerAccount = useCallback(
    async (
      firstName: string,
      lastName: string,
      email: string,
      password: string,
      confirmationPassword: string,
      generatePassword: boolean,
    ) => {
      let registrationResponse: RegistrationResponse = {
        success: false,
      }

      if (generatePassword === true) {
        const uuidv4 = require('uuid/v4')
        const generated: string = uuidv4()
        password = generated
        confirmationPassword = generated
      }

      try {
        setIsAuthenticating(true)
        const response = await fetch(`${ENDPOINT_URL}/api/v1/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            full_name: `${firstName} ${lastName}`,
            email,
            password,
            password_confirmation: confirmationPassword,
          }),
        })
        setIsAuthenticating(false)
        if (response.status.toString().match('^(2.{2})$')) {
          const result = await response.json()
          if (result.data.email) {
            registrationResponse = {
              email: result.data.email,
              full_name: result.data.full_name,
              success: true,
            }
          } else {
            throw Error(
              'Unable to register at this time. Unexpected registration result.',
            )
          }
        } else if (response.status.toString().match('^(4.{2})$')) {
          const result = await response.json()
          const error = processError(result.data.error)
          registrationResponse = { error, success: false }
        } else {
          throw Error(
            `Server responded with unexpected status code: ${response.status} `,
          )
        }
      } catch (error) {
        console.error(error)
        setIsAuthenticating(false)
        registrationResponse = {
          success: false,
          error: [
            { fieldName: 'general', errors: ['Critical Registration Error'] },
          ],
        }
      } finally {
        return registrationResponse
      }
    },
    [],
  )

  const logout = useCallback(() => {
    setauthorizationToken(undefined)
    localStorage.clear()
  }, [])

  const value: AuthenticationProviderState = useMemo(
    () => ({
      login,
      registerAccount,
      logout,
      authorizationToken,
      fullName,
      userIdentifier,
      isAuthenticating,
    }),
    [
      login,
      registerAccount,
      logout,
      authorizationToken,
      fullName,
      userIdentifier,
      isAuthenticating,
    ],
  )
  return (
    <AuthenticationContext.Provider value={value}>
      {props.children}
    </AuthenticationContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthenticationContext)
}
