import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'

interface ScreenContentProps {
  children?: React.ReactNode
  className?: any
}

const Layout = styled.section`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-area: ${theme.ui.layout.content};
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 0;
    padding: 4.0625rem 0.9375rem 4.0625rem 3.125rem;
  }

  width: 100%;
  height: auto;
  margin-bottom: ${theme.ui.gutter.small};
  padding: 1.25rem 1.25rem;
`

const ScreenContent: React.FC<ScreenContentProps> = ({
  children,
  className,
}) => {
  return <Layout className={className}>{children}</Layout>
}

export default ScreenContent
