import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { SessionUser } from 'ui/components/Session'
import { InputText } from 'ui/components/Input'

import { useAuth } from 'context/AuthenticationContext'
import { useSession } from 'context/SessionContext'
import { User } from 'context/SessionData'

import UserImg from 'ui/assets/image/user.png'

interface UserListProps {
  selectedUserIdentifiers: User[] | undefined
  setSelectedUserIdentifieres: React.Dispatch<
    React.SetStateAction<User[] | undefined>
  >
}

const Search = styled.div`
  position: sticky;
  top: 0;
  z-index: 50;
  width: 100%;
  height: auto;
  margin-bottom: 0.78125rem;
  padding-bottom: 0.78125rem;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%
  );
`

const List = styled.ul`
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  list-style: none;
`

const UserList: React.FC<UserListProps> = ({
  selectedUserIdentifiers,
  setSelectedUserIdentifieres,
}) => {
  const { authorizationToken } = useAuth()
  const { fetchUserList } = useSession()

  const [userList, setUserList] = useState<User[]>()
  const [elements, setElements] = useState<JSX.Element[]>()
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const fetchUsers = async () => {
      if (authorizationToken) {
        const result = await fetchUserList(authorizationToken)
        setUserList(result.userList)
      } else {
        //TODO: Handle authentication failure
      }
    }

    const handleUserSelect = (userIdentifier: string, state: boolean) => {
      if (state === true) {
        const newUser: User = {
          userIdentifier,
        }
        setSelectedUserIdentifieres([
          ...(selectedUserIdentifiers || []),
          newUser,
        ])
      } else {
        if (selectedUserIdentifiers && selectedUserIdentifiers.length > 0) {
          setSelectedUserIdentifieres(
            selectedUserIdentifiers.filter(value => {
              return value.userIdentifier !== userIdentifier
            }),
          )
        }
      }
    }

    if (userList === undefined) {
      fetchUsers()
    } else {
      setElements(
        userList
          .filter(value => {
            if (searchTerm) {
              return (
                value.fullName &&
                value.fullName.toLowerCase().includes(searchTerm.toLowerCase())
              )
            } else {
              return true
            }
          })
          .map(value => {
            const isSelected =
              (selectedUserIdentifiers || []).filter(item => {
                return item.userIdentifier === value.userIdentifier
              }).length === 1

            return (
              <SessionUser
                key={value.userIdentifier}
                userIdentifier={value.userIdentifier}
                name={value.fullName}
                image={UserImg}
                selected={isSelected}
                onClick={handleUserSelect}
              />
            )
          }),
      )
    }
  }, [
    setElements,
    userList,
    fetchUserList,
    authorizationToken,
    searchTerm,
    selectedUserIdentifiers,
    setSelectedUserIdentifieres,
  ])

  return (
    <>
      <Search>
        <InputText
          placeholder="Search"
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setSearchTerm(e.target.value.toString())
          }
        />
      </Search>

      <List>{elements}</List>
    </>
  )
}

export default UserList
