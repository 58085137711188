import React, { useCallback, useContext, useMemo } from 'react'

import {
  CreateSessionResponse,
  FetchSessionDetailResponse,
  FetchSessionListResponse,
  SessionSchedule,
  SessionScheduleItem,
  SearchUsersResponse,
  UpdateSessionResponse,
  User,
  DeleteSessionResponse,
} from './SessionData'
import moment from 'moment'
import { get, union } from 'lodash'

export interface SessionProviderProperties {
  children?: React.ReactNode
}

export interface SessionData {}

export interface SessionProviderState {
  sessionList?: SessionSchedule
  fetchSessionList: (
    authorizationToken: string,
  ) => Promise<FetchSessionListResponse>
  fetchSessionDetail: (
    authorizationToken: string,
    sessionIdentifier: string,
  ) => Promise<FetchSessionDetailResponse>
  createSession: (
    authorizationToken: string,
    userIdentifier: string,
    sessionScheduleItem: SessionScheduleItem,
  ) => Promise<CreateSessionResponse>
  updateSession: (
    authorizationToken: string,
    userIdentifier: string,
    sessionScheduleItem: SessionScheduleItem,
  ) => Promise<UpdateSessionResponse>
  deleteSession: (
    authorizationToken: string,
    sessionScheduleItemIdentifier: string,
  ) => Promise<DeleteSessionResponse>
  fetchUserList: (authorizationToken: string) => Promise<SearchUsersResponse>
}

export const SessionContext = React.createContext<SessionProviderState>({
  sessionList: {},
  fetchSessionList(_authorizationToken: string) {
    throw new Error('Initilization Error - Context is not initialized')
  },
  fetchSessionDetail(_authorizationToken: string, _sessionIdentifier: string) {
    throw new Error('Initilization Error - Context is not initialized')
  },
  createSession(
    _authorizationToken: string,
    _userIdentifier: string,
    _sessionScheduleItem: SessionScheduleItem,
  ) {
    throw new Error('Initilization Error - Context is not initialized')
  },
  updateSession(
    _authorizationToken: string,
    _userIdentifier: string,
    _sessionScheduleItem: SessionScheduleItem,
  ) {
    throw new Error('Initilization Error - Context is not initialized')
  },
  deleteSession(
    _authorizationToken: string,
    _sessionScheduleItemIdentifier: string,
  ) {
    throw new Error('Initilization Error - Context is not initialized')
  },
  fetchUserList(_authorizationToken: string) {
    throw new Error('Initilization Error - Context is not initialized')
  },
})
SessionContext.displayName = 'SessionContext'

const ENDPOINT_URL =
  process.env.REACT_APP_QRS_CONNECT_API_HOST_NAME || 'http://localhost:4000'

export function SessionProvider(props: SessionProviderProperties) {
  const fetchSessionList = useCallback(async (authorizationToken: string) => {
    let fetchSessionListResponse: FetchSessionListResponse = {}

    try {
      //Get all users, and then map the users identifiers into their names. This is a hack.
      const profileResponse = await fetch(`${ENDPOINT_URL}/api/v1/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: authorizationToken,
        },
      })

      const response = await fetch(`${ENDPOINT_URL}/api/v1/remote_sessions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: authorizationToken,
        },
      })

      if (response.status.toString().match('^(2.{2})$')) {
        const result = await response.json()
        if (Array.isArray(result.data)) {
          const profileResult = await profileResponse.json()

          const scheduleItems: SessionScheduleItem[] = result.data.map(
            (value: any) => {
              let createdByUser: User = {
                userIdentifier: '1234',
                fullName: 'Someone',
              }

              if (Array.isArray(profileResult.data) && value.created_by) {
                const currentIdentifier = value.created_by
                //Created by User
                const userData = profileResult.data.filter((value: any) => {
                  return value.id === currentIdentifier
                })[0]

                if (get(userData, 'id') && get(userData, 'full_name')) {
                  createdByUser = {
                    userIdentifier: userData.id,
                    fullName: userData.full_name,
                  }
                }
              }

              const item: SessionScheduleItem = {
                id: value.id,
                session_name: value.session_name,
                start_at: value.start_at
                  ? moment(value.start_at).toDate()
                  : undefined,
                end_at: value.end_at
                  ? moment(value.end_at).toDate()
                  : undefined,
                performed_at: value.performed_at
                  ? moment(value.performed_at).toDate()
                  : undefined,
                performance_end_at: value.performed_end_at
                  ? moment(value.performed_end_at).toDate()
                  : undefined,
                public: value.public,
                performed_by: (value.performers || []).map(
                  (userIdentifier: string) => {
                    return { userIdentifier }
                  },
                ),
                created_by: createdByUser,
                invitees: (value.invitees || []).map(
                  (userIdentifier: string) => {
                    return { userIdentifier }
                  },
                ),
                attendees: (value.attendees || []).map(
                  (userIdentifier: string) => {
                    return { userIdentifier }
                  },
                ),
              }
              return item
            },
          )

          fetchSessionListResponse = {
            sessionSchedule: {
              scheduleItems,
            },
          }
        } else if (result.data.error) {
          fetchSessionListResponse = {
            error: 'Session list is currently unavailable.',
          }
        } else {
          throw Error('Fetch session list unavailable. Unexpected Result.')
        }
      } else {
        throw Error(
          `Server responded with unexpected status code: ${response.status} `,
        )
      }
    } catch (error) {
      console.error(error)
      fetchSessionListResponse = {
        error: 'Sessions list is unavailable at this time.',
      }
    } finally {
      return fetchSessionListResponse
    }
  }, [])

  const fetchSessionDetail = useCallback(
    async (authorizationToken: string, sessionIdentifier: string) => {
      let fetchSessionDetailResponse: FetchSessionDetailResponse = {}

      try {
        const response = await fetch(
          `${ENDPOINT_URL}/api/v1/remote_sessions/${sessionIdentifier}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              authorization: authorizationToken,
            },
          },
        )

        if (response.status.toString().match('^(2.{2})$')) {
          const result = await response.json()
          if (result.data.id) {
            fetchSessionDetailResponse = {
              sessionScheduleItem: {
                id: result.data.id,
                type: result.data.public ? 'Performance' : 'Lesson',
                session_name: result.data.session_name,
                start_at: result.data.start_at
                  ? moment(result.data.start_at).toDate()
                  : undefined,
                end_at: result.data.end_at
                  ? moment(result.data.end_at).toDate()
                  : undefined,
                performed_at: result.data.performed_at
                  ? moment(result.data.performed_at).toDate()
                  : undefined,
                performance_end_at: result.data.performance_end_at
                  ? moment(result.data.performance_end_at).toDate()
                  : undefined,
                public: result.data.public,
                performed_by: (result.data.performers || []).map(
                  (userIdentifier: string) => {
                    return { userIdentifier }
                  },
                ),
                created_by: { userIdentifier: result.data.created_by },
                invitees: (result.data.invitees || []).map(
                  (userIdentifier: string) => {
                    return { userIdentifier }
                  },
                ),
                attendees: (result.data.attendees || []).map(
                  (userIdentifier: string) => {
                    return { userIdentifier }
                  },
                ),
              },
            }

            //Get all users, and then map the users identifiers into their names.
            const profileResponse = await fetch(
              `${ENDPOINT_URL}/api/v1/users`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  authorization: authorizationToken,
                },
              },
            )

            //This is currently the only way to get the user data for the list of users here.
            //This is not ideal, and is 'expensive'
            if (profileResponse.status.toString().match('^(2.{2})$')) {
              const profileResult = await profileResponse.json()
              if (Array.isArray(profileResult.data)) {
                // Attendees Users
                if (
                  fetchSessionDetailResponse &&
                  fetchSessionDetailResponse.sessionScheduleItem &&
                  fetchSessionDetailResponse.sessionScheduleItem.attendees
                ) {
                  fetchSessionDetailResponse.sessionScheduleItem.attendees = fetchSessionDetailResponse.sessionScheduleItem.attendees.map(
                    attendee => {
                      const userData = profileResult.data.filter(
                        (value: any) => {
                          return value.id === attendee.userIdentifier
                        },
                      )[0]
                      const user: User = {
                        userIdentifier: userData.id,
                        fullName: userData.full_name,
                      }
                      return user
                    },
                  )
                }

                if (
                  fetchSessionDetailResponse &&
                  fetchSessionDetailResponse.sessionScheduleItem &&
                  fetchSessionDetailResponse.sessionScheduleItem.performed_by
                ) {
                  fetchSessionDetailResponse.sessionScheduleItem.performed_by = fetchSessionDetailResponse.sessionScheduleItem.performed_by.map(
                    performer => {
                      const userData = profileResult.data.filter(
                        (value: any) => {
                          return value.id === performer.userIdentifier
                        },
                      )[0]
                      const user: User = {
                        userIdentifier: userData.id,
                        fullName: userData.full_name,
                      }
                      return user
                    },
                  )
                }

                if (
                  fetchSessionDetailResponse &&
                  fetchSessionDetailResponse.sessionScheduleItem &&
                  fetchSessionDetailResponse.sessionScheduleItem.created_by
                ) {
                  const currentIdentifier =
                    fetchSessionDetailResponse.sessionScheduleItem.created_by
                      .userIdentifier
                  //Created by User
                  const userData = profileResult.data.filter((value: any) => {
                    return value.id === currentIdentifier
                  })[0]

                  const user: User = {
                    userIdentifier: userData.id,
                    fullName: userData.full_name,
                  }

                  fetchSessionDetailResponse.sessionScheduleItem.created_by = user
                }
              }
            } else {
              throw Error('Unable to get master users list')
            }
          } else if (result.data.error) {
            fetchSessionDetailResponse = {
              error: 'Fetch session detail unavailable.',
            }
          } else {
            throw Error('Fetch session detail unavailable. Unexpected Result.')
          }
        } else {
          throw Error(
            `Server responded with unexpected status code: ${response.status} `,
          )
        }
      } catch (error) {
        console.error(error)
        fetchSessionDetailResponse = {
          error: 'Session detail is not available at this time.',
        }
      } finally {
        return fetchSessionDetailResponse
      }
    },
    [],
  )

  const createSession = useCallback(
    async (
      authorizationToken: string,
      userIdentifier: string,
      sessionScheduleItem: SessionScheduleItem,
    ) => {
      let createSessionResponse: CreateSessionResponse = {
        status: false,
      }

      try {
        const response = await fetch(`${ENDPOINT_URL}/api/v1/remote_sessions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: authorizationToken,
          },
          body: JSON.stringify({
            session_name: sessionScheduleItem.session_name,
            start_at: moment(sessionScheduleItem.start_at)
              .utc()
              .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            end_at: moment(sessionScheduleItem.end_at)
              .utc()
              .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            attendees: (sessionScheduleItem.attendees || []).map(value => {
              return value.userIdentifier
            }),
            performers: [userIdentifier],
            invitees: (sessionScheduleItem.invitees || []).map(value => {
              return value.userIdentifier
            }),
          }),
        })

        if (response.status.toString().match('^(2.{2})$')) {
          const result = await response.json()
          if (result.data.id) {
            createSessionResponse = {
              status: true,
              identifier: result.data.id,
            }
          } else if (result.data.error) {
            createSessionResponse = {
              status: false,
              error: 'Failed to Create Session.',
            }
          } else {
            throw Error('Create session unavailable. Unexpected Result.')
          }
        } else {
          throw Error(
            `Server responded with unexpected status code: ${response.status} `,
          )
        }
      } catch (error) {
        console.error(error)
        createSessionResponse = {
          status: false,
          error: 'Unable to create session at this time.',
        }
      } finally {
        return createSessionResponse
      }
    },
    [],
  )

  const updateSession = useCallback(
    async (
      authorizationToken: string,
      userIdentifier: string,
      sessionScheduleItem: SessionScheduleItem,
    ) => {
      let updateSessionResponse: UpdateSessionResponse = {
        status: false,
      }

      try {
        if (!sessionScheduleItem.id) {
          throw Error(
            `Critical error, found sessionScheduleItem.id to be undefined.`,
          )
        }

        const request: any = {
          id: sessionScheduleItem.id,
          session_name: sessionScheduleItem.session_name,
          start_at: moment(sessionScheduleItem.start_at)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          end_at: moment(sessionScheduleItem.end_at)
            .utc()
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
          attendees: union(
            (sessionScheduleItem.attendees || []).map(value => {
              return value.userIdentifier
            }),
          ),
          performers: [userIdentifier],
          invitees: (sessionScheduleItem.invitees || []).map(value => {
            return value.userIdentifier
          }),
        }

        if (
          sessionScheduleItem.created_by &&
          sessionScheduleItem.created_by.userIdentifier === userIdentifier
        ) {
          if (sessionScheduleItem.performed_at) {
            request.performed_at = moment(sessionScheduleItem.performed_at)
              .utc()
              .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
          }

          if (sessionScheduleItem.performance_end_at) {
            request.performance_end_at = moment(
              sessionScheduleItem.performance_end_at,
            )
              .utc()
              .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
          }
        }

        const response = await fetch(
          `${ENDPOINT_URL}/api/v1/remote_sessions/${sessionScheduleItem.id}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              authorization: authorizationToken,
            },
            body: JSON.stringify(request),
          },
        )

        if (response.status.toString().match('^(2.{2})$')) {
          const result = await response.json()
          if (result.data.id) {
            updateSessionResponse = {
              status: true,
            }
          } else if (result.data.error) {
            console.error(result.data.error)
            updateSessionResponse = {
              status: false,
              error: 'Failed to Update Session.',
            }
          } else {
            throw Error('Update session unavailable. Unexpected Result.')
          }
        } else {
          throw Error(
            `Server responded with unexpected status code: ${response.status} `,
          )
        }
      } catch (error) {
        console.error(error)
        updateSessionResponse = {
          status: false,
          error: 'Unable to update session at this time.',
        }
      } finally {
        return updateSessionResponse
      }
    },
    [],
  )

  const deleteSession = useCallback(
    async (
      authorizationToken: string,
      sessionScheduleItemIdentifier: string,
    ) => {
      let deleteSessionResponse: DeleteSessionResponse = {
        status: false,
      }

      try {
        const response = await fetch(
          `${ENDPOINT_URL}/api/v1/remote_sessions/${sessionScheduleItemIdentifier}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              authorization: authorizationToken,
            },
          },
        )

        if (response.status === 204) {
          deleteSessionResponse = {
            status: true,
          }
        } else if (response.status.toString().match('^(2.{2})$')) {
          const result = await response.json()
          if (result.data.success) {
            deleteSessionResponse = {
              status: true,
            }
          } else if (result.data.error) {
            deleteSessionResponse = {
              status: false,
              error: 'Failed to Delete Session.',
            }
          } else {
            throw Error('Delete session unavailable. Unexpected Result.')
          }
        } else {
          throw Error(
            `Server responded with unexpected status code: ${response.status} `,
          )
        }
      } catch (error) {
        console.error(error)
        deleteSessionResponse = {
          status: false,
          error: 'Delete to create session at this time.',
        }
      } finally {
        return deleteSessionResponse
      }
    },
    [],
  )

  const fetchUserList = useCallback(async (authorizationToken: string) => {
    let searchUsersResponse: SearchUsersResponse = {}

    try {
      const response = await fetch(`${ENDPOINT_URL}/api/v1/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: authorizationToken,
        },
      })

      if (response.status.toString().match('^(2.{2})$')) {
        const result = await response.json()
        if (Array.isArray(result.data)) {
          const userList: User[] = result.data.map((value: any) => {
            return {
              userIdentifier: value.id,
              fullName: value.full_name,
            }
          })
          searchUsersResponse = {
            userList,
          }
        } else if (result.data.error) {
          searchUsersResponse = {
            error: 'The users list is unavailable.',
          }
        } else {
          throw Error('Create session unavailable. Unexpected Result.')
        }
      } else {
        throw Error(
          `Server responded with unexpected status code: ${response.status} `,
        )
      }
    } catch (error) {
      console.error(error)
      searchUsersResponse = {
        error: 'Unable to create session at this time.',
      }
    } finally {
      return searchUsersResponse
    }
  }, [])

  const value: SessionProviderState = useMemo(
    () => ({
      fetchSessionDetail,
      fetchSessionList,
      createSession,
      updateSession,
      deleteSession,
      fetchUserList,
    }),
    [
      fetchSessionDetail,
      fetchSessionList,
      createSession,
      updateSession,
      deleteSession,
      fetchUserList,
    ],
  )

  return (
    <SessionContext.Provider value={value}>
      {props.children}
    </SessionContext.Provider>
  )
}

export function useSession() {
  return useContext(SessionContext)
}
