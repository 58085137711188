import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import useForm from 'react-hook-form'

import 'react-datepicker/dist/react-datepicker.css'
import 'ui/components/DatePicker/DatePickerStyles.css'
import { InputOption, InputSelect, InputText, InputWrapper } from '../Input'
import { SessionScheduleItem } from 'context/SessionData'
import moment from 'moment'

import { useWindowWidth } from 'hooks'

interface SessionDetailsFormProps {
  setSession: React.Dispatch<React.SetStateAction<SessionScheduleItem>>
  session: SessionScheduleItem
  sessionDetailFormShouldTriggerValidation: boolean
  sessionDetailFormValidationResult: (result: boolean) => Promise<void>
}

const Layout = styled.form`
  width: 100%;
  min-height: 100%;
`

const StyledInputText = styled(InputText)`
  margin-bottom: 0.9375rem;
`

const StyledInputSelect = styled(InputSelect)`
  margin-bottom: 0.9375rem;
`

const StyledInputWrapper = styled(InputWrapper)`
  margin-bottom: 0.9375rem;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    width: 100%;
  }
`

const SessionDetailsForm: React.FC<SessionDetailsFormProps> = ({
  setSession,
  session,
  sessionDetailFormShouldTriggerValidation,
  sessionDetailFormValidationResult,
}) => {
  const { register, errors, watch, setValue, triggerValidation } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      start_datetime: session.start_at,
      end_datetime: session.end_at,
    },
  })

  const [name, setName] = useState(session.session_name || '')
  const [type, setType] = useState(session.type || '')
  const [startDate, setStartDate] = useState(session.start_at || undefined)
  const [endDate, setEndDate] = useState(session.end_at || undefined)

  const validateDates = () => {
    if (startDate && endDate) {
      if (moment(startDate).isAfter(moment(endDate))) {
        return 'Start Date must occur be before End Date.'
      }

      //TODO: Ask QRS if they really want this ??
      // if (moment().isSameOrAfter(moment(startDate))) {
      //   return 'Start Date must occur in the future.'

      // }

      if (moment().isSameOrAfter(moment(endDate))) {
        return 'End Date must occur in the future.'
      }
    }
    return true
  }

  register(
    { name: 'start_datetime' },
    {
      required: 'A valid Start Date is required',
      validate: { validateDate: () => validateDates() },
    },
  )
  register(
    { name: 'end_datetime' },
    {
      required: 'A valid End Date is required',
      validate: { validateDate: () => validateDates() },
    },
  )

  watch()

  useEffect(() => {
    setSession(session)

    if (sessionDetailFormShouldTriggerValidation === true) {
      const asyncTrigger = async () => {
        const result = await triggerValidation()

        const sessionScheduleItem: SessionScheduleItem = session
        sessionScheduleItem.session_name = name
        sessionScheduleItem.type = type
        sessionScheduleItem.start_at = startDate
        sessionScheduleItem.end_at = endDate
        setSession(sessionScheduleItem)
        await sessionDetailFormValidationResult(result)
      }

      asyncTrigger()
    } else if (name && type && startDate && endDate) {
      triggerValidation()
    }
  }, [
    name,
    type,
    startDate,
    endDate,
    setSession,
    register,
    triggerValidation,
    sessionDetailFormValidationResult,
    sessionDetailFormShouldTriggerValidation,
    session,
  ])

  const windowSize = useWindowWidth()
  const displayMobileDatePicker = windowSize <= 768

  return (
    <Layout>
      <StyledInputText
        label="Session Name"
        placeholder="Session Name"
        name="session_name"
        autoComplete="off"
        defaultValue={name}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setName(e.target.value)
        }
        register={register({
          required: 'Session Name is required',
        })}
        hasError={errors.session_name}
        errorMessage={
          errors.session_name ? errors.session_name.message : undefined
        }
      />

      <StyledInputSelect
        label="Session Type"
        name="session_type"
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setType(e.target.value)
        }
        defaultValue={type}
        register={register({
          required: 'Session Type is required',
        })}
        hasError={errors.session_type}
        errorMessage={
          errors.session_type ? errors.session_type.message : undefined
        }
      >
        <InputOption disabled hidden value="" label="Choose an option" />
        <InputOption value="lesson" label="Lesson" />
        <InputOption value="performance" label="Performance" />
      </StyledInputSelect>

      <StyledInputWrapper
        label="Start Time"
        hasError={errors.start_datetime}
        errorMessage={
          errors.start_datetime ? errors.start_datetime.message : undefined
        }
      >
        <DatePicker
          dateFormat="yyyy/MM/dd h:mm aa"
          onChange={date => {
            setValue('start_datetime', date)
            setStartDate(date || undefined)
            triggerValidation({ name: 'start_datetime' })
          }}
          selected={startDate}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          name="start_datetime"
          onChangeRaw={e => {
            e.preventDefault()
          }}
          autoComplete="off"
          withPortal={displayMobileDatePicker}
          popperModifiers={{
            offset: {
              enabled: false,
              offset: '0px, 10px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'scrollParent',
            },
          }}
        />
      </StyledInputWrapper>

      <StyledInputWrapper
        label="End Time"
        hasError={errors.end_datetime}
        errorMessage={
          errors.end_datetime ? errors.end_datetime.message : undefined
        }
      >
        <DatePicker
          dateFormat="yyyy/MM/dd h:mm aa"
          onChange={date => {
            setValue('end_datetime', date)
            setEndDate(date || undefined)
            triggerValidation({ name: 'end_datetime' })
          }}
          selected={endDate}
          showTimeSelect
          timeIntervals={15}
          timeCaption="Time"
          name="end_datetime"
          onChangeRaw={e => {
            e.preventDefault()
          }}
          autoComplete="off"
          withPortal={displayMobileDatePicker}
          popperModifiers={{
            offset: {
              enabled: false,
              offset: '0px, 20px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'scrollParent',
            },
          }}
        />
      </StyledInputWrapper>
    </Layout>
  )
}

export default SessionDetailsForm
