import styled from 'styled-components'
import theme from 'ui/theme'

const Error = styled.span`
  width: 100%;
  margin-top: 0.625rem;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  color: ${theme.validation.error};
`

export default Error
