import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import moment from 'moment'

import {
  Button,
  H1,
  Header,
  MetricMapper,
  Notification,
  Panel,
  ScreenContainer,
  ScreenContent,
  SessionActions,
  SessionDetails,
  SessionMetrics,
} from 'ui/components'

import { useAuth } from 'context/AuthenticationContext'
import { useInterval } from 'hooks/useInterval'

import PanelCreateNewSession from './PanelCreateNewSession'
import PanelSessionsSchedule from './PanelSessionsSchedule'

interface Props {
  userName?: string
}

const CreateNewSessionButton = styled(Button)`
  width: 12.1875rem;
`

const ScheduleScreen: React.FC<Props> = () => {
  const [error, setErrorMessage] = useState<string>('')
  const setError = (error: string) => {
    setErrorMessage(error)
    setTimeout(() => {
      setErrorMessage('')
    }, 5000)
  }

  const clearError = () => {
    setErrorMessage('')
  }

  const { logout, fullName } = useAuth()
  let location = useLocation()
  let history = useHistory()

  if (location.state && location.state.deleteSuccess === true) {
    location.state.deleteSuccess = false
    setError('Session deleted successfully!')
    history.replace('/schedule', null)
  }

  // TODO need to update these values after an interval of time
  const [currentDate, setCurrentDate] = useState(moment().format('L'))
  const [currentTime, setCurrentTime] = useState(moment().format('LT'))

  const [createNewSession, setCreateNewSession] = useState(false)

  useInterval(() => {
    setCurrentDate(moment().format('L'))
    setCurrentTime(moment().format('LT'))
  }, 1000)

  const [sessionCount, setSessionCount] = useState(0)

  const metrics = [
    {
      label: 'Current Time',
      value: currentTime,
    },
    {
      label: 'Date',
      value: currentDate,
    },
    {
      label: `Today's Sessions`,
      value: sessionCount,
    },
  ]

  return (
    <ScreenContainer>
      <Notification
        messageType="alert"
        message={error}
        isActive={error !== ''}
        onDismiss={() => clearError()}
      />

      <Header>
        <Button
          type="button"
          bgType="border"
          size="small"
          color="secondary"
          width={100}
          label="Logout"
          onClick={logout}
        />
      </Header>

      <ScreenContent>
        <SessionDetails>
          <H1>{fullName ? `Welcome, ${fullName}` : 'Welcome'}</H1>

          <SessionMetrics>
            <MetricMapper items={metrics} />
          </SessionMetrics>

          <SessionActions>
            <CreateNewSessionButton
              type="button"
              bgType="fill"
              size="large"
              color="primary"
              width={150}
              label="Create new session"
              onClick={() => setCreateNewSession(true)}
            />
          </SessionActions>
        </SessionDetails>
      </ScreenContent>

      <Panel>
        <Transition
          items={!createNewSession}
          from={{
            opacity: !createNewSession ? 1 : 0,
          }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          config={config.stiff}
        >
          {show =>
            show &&
            (props => (
              <PanelSessionsSchedule
                style={props}
                onCreateNewSession={() => setCreateNewSession(true)}
                setSessionCount={setSessionCount}
              />
            ))
          }
        </Transition>

        <Transition
          items={createNewSession}
          from={{
            position: 'absolute',
            left: '100%',
            backgroundColor: 'white',
          }}
          enter={{ left: '0%' }}
          leave={{ left: '100%' }}
          config={config.stiff}
        >
          {show =>
            show &&
            (props => (
              <PanelCreateNewSession
                style={props}
                onBack={() => setCreateNewSession(false)}
                onCancel={() => setCreateNewSession(false)}
              />
            ))
          }
        </Transition>
      </Panel>
    </ScreenContainer>
  )
}

export default ScheduleScreen
