import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'

interface PanelProps {
  children?: React.ReactNode
  className?: any
}

const Layout = styled.section`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-area: ${theme.ui.layout.panel};
    grid-column: 2;
    grid-row: 1 / span 2;
    width: 28.125rem;
    height: 100%;
    padding: 0.9375rem 0.9375rem;
  }

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 80vh;
  padding: 0 0;
  overflow: hidden;
`

const Inner = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    width: 100%;
    height: 100%;
    border-bottom-right-radius: ${theme.ui.radius / 16}rem;
    border-bottom-left-radius: ${theme.ui.radius / 16}rem;
  }

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0;
  border-radius: ${theme.ui.radius / 16}rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: ${theme.color.white};
  overflow: hidden;
`

const Panel: React.FC<PanelProps> = ({ children, className }) => {
  return (
    <Layout className={className}>
      <Inner>{children}</Inner>
    </Layout>
  )
}

export default Panel
