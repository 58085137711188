import React, { Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import './index.css'
import { AuthenticationProvider } from 'context/AuthenticationContext'
import { SessionProvider } from 'context/SessionContext'

import {
  AuthCreateAccountScreen,
  AuthForgotPasswordScreen,
  AuthLoginScreen,
  AuthResetPasswordScreen,
  DemoCreateAccountScreen,
  NotFoundScreen,
  ProfileScreen,
  ScheduleScreen,
  SessionScreen,
  WalkthroughScreen,
} from 'ui/screens'
import theme from 'ui/theme'

const AppGlobalStyles = createGlobalStyle`
  html {
    @media (min-width: ${theme.ui.breakpoint.xxLarge}) {
      font-size: 18px;
    }

    font-size: 16px;
  }
`

function App() {
  console.log(process.env.REACT_APP_VERSION)

  return (
    <Fragment>
      <AppGlobalStyles />

      <AuthenticationProvider>
        <Router>
          <Switch>
            <Route path="/profile">
              <ProfileScreen />
            </Route>

            <Route path="/schedule">
              <SessionProvider>
                <ScheduleScreen />
              </SessionProvider>
            </Route>

            <Route
              path="/session/:sessionIdentifier"
              render={props => (
                <SessionProvider>
                  <SessionScreen {...props} />
                </SessionProvider>
              )}
            />

            <Route path="/namm">
              <DemoCreateAccountScreen />
            </Route>

            <Route path="/forgot-password">
              <AuthForgotPasswordScreen />
            </Route>

            <Route path="/reset-password">
              <AuthResetPasswordScreen />
            </Route>

            <Route path="/create-account">
              <AuthCreateAccountScreen />
            </Route>

            <Route path="/walkthrough">
              <WalkthroughScreen />
            </Route>

            <Route path="/" exact>
              <AuthLoginScreen />
            </Route>

            <Route component={NotFoundScreen} />
          </Switch>
        </Router>
      </AuthenticationProvider>
    </Fragment>
  )
}

export default App
