import React from 'react'
import styled from 'styled-components'

import { H2, Text } from '../Typography'
import PanelBackButton from './PanelBackButton'
import theme from 'ui/theme'

interface PanelHeaderProps {
  type?: 'default' | 'back'
  title?: string
  subtitle?: string
  onBack?: () => void
  children?: React.ReactNode
}

const Layout = styled.header`
  grid-area: ${theme.panel.layout.header};
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: ${({ type }: PanelHeaderProps) =>
    type === 'back' ? 'flex-start' : 'space-between'};
  align-items: ${({ subtitle }: PanelHeaderProps) =>
    subtitle ? 'flex-start' : 'center'};
  width: 100%;
  height: ${({ subtitle }: PanelHeaderProps) =>
    subtitle ? '8.125rem' : '6.875rem'};
  padding: 3.125rem ${theme.panel.gutter / 16}rem 1.875rem;
`

const TitleContainer = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`

const Title = styled(H2)``

const Subtitle = styled(Text)`
  margin-top: 0.625rem;
`

const PanelHeader: React.FC<PanelHeaderProps> = ({
  type = 'default',
  title,
  subtitle,
  onBack,
  children,
}) => {
  return (
    <Layout type={type} subtitle={subtitle}>
      {type === 'back' && onBack && (
        <PanelBackButton icon="NavBack" onClick={onBack} />
      )}
      <TitleContainer>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitleContainer>
      {children}
    </Layout>
  )
}

export default PanelHeader
