import styled from 'styled-components'
import theme from 'ui/theme'

const CardContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 1.25rem 1.25rem;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  background-color: ${theme.color.background};
`

export default CardContent
