import React from 'react'
import styled from 'styled-components'

import {
  Button,
  InputOption,
  InputSelect,
  ModalActions,
  ModalContent,
  ModalHeader,
} from 'ui/components'

interface Props {
  onCancel?: () => void
  onSubmit?: () => void
}

const Form = styled.form`
  width: 100%;
  margin-top: 25px;
`

const StyledModalActions = styled(ModalActions)`
  margin-top: 30px;
`

const StyledButton = styled(Button)`
  width: 100%;
`

const ModalSessionDevices: React.FC<Props> = ({ onCancel, onSubmit }) => {
  function handleConfirm() {
    onSubmit && onSubmit()
  }

  return (
    <ModalContent>
      <ModalHeader title="Select Device" />

      <Form onSubmit={onSubmit}>
        <InputSelect label="Available Devices" name="availableDevices">
          <InputOption disabled hidden label="Select a device" />
        </InputSelect>
      </Form>

      <StyledModalActions>
        <StyledButton
          type="button"
          bgType="border"
          size="default"
          color="primary"
          label="Cancel"
          onClick={onCancel}
        />
        <StyledButton
          type="submit"
          bgType="fill"
          size="default"
          color="primary"
          label="Join session"
          onClick={handleConfirm}
        />
      </StyledModalActions>
    </ModalContent>
  )
}

export default ModalSessionDevices
