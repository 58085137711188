import styled from 'styled-components'
import { ScreenContainer } from '../Screen'
import theme from 'ui/theme'

const AuthScreenContainer = styled(ScreenContainer)`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      '${theme.ui.layout.header} ${theme.ui.layout.panel}'
      '${theme.ui.layout.content} ${theme.ui.layout.panel}';
    grid-gap: 0 3.125rem;
    height: 100vh;
    padding: .9375rem .9375rem .9375rem 3.125rem;
    background-color:  #1f1f1f;
  }

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: auto;
  padding: 0 ${theme.ui.gutter.small};
  background-color: white;
`

export default AuthScreenContainer
