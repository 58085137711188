import React from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import theme from 'ui/theme'

interface Props {
  position?: 'start' | 'middle' | 'end'
  area?: 'all' | 'content'
  isActive: any
  previous?: any
  next?: any
  children?: React.ReactNode
}
const LayoutAll = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const LayoutContent = styled.div`
  grid-area: ${theme.panel.layout.content};
  width: 100%;
  position: absolute;
`

const PanelTransition: React.FC<Props> = ({
  position = 'start',
  area = 'all',
  isActive,
  previous,
  next,
  children,
}) => {
  // TODO these transitions are all going to be the same until we figure out the data model to change the middle transition
  // The problem is we are not using one unified state to control the position in usage
  // In theory everything commented out here is correct and will work once we define data usage and state
  if (position === 'start') {
    return (
      <Transition
        items={isActive}
        from={{
          left: isActive ? '0%' : '100%',
          // left: next ? '-100%' : '100%',
          opacity: 0,
        }}
        enter={{
          left: '0%',
          opacity: 1,
        }}
        leave={{
          left: '-100%',
          opacity: 0,
        }}
        config={config.stiff}
      >
        {area === 'all'
          ? show =>
              show && (props => <LayoutAll style={props}>{children}</LayoutAll>)
          : show =>
              show &&
              (props => (
                <LayoutContent style={props}>{children}</LayoutContent>
              ))}
      </Transition>
    )
  }

  if (position === 'middle') {
    return (
      <Transition
        items={isActive}
        from={{
          left: '100%',
          // left: current || previous ? '100%' : '-100%',
          opacity: 0,
        }}
        enter={{
          left: '0%',
          opacity: 1,
        }}
        leave={{
          left: '-100%',
          // left: current || next ? '-100%' : '100%'
          opacity: 0,
        }}
        config={config.stiff}
      >
        {area === 'all'
          ? show =>
              show && (props => <LayoutAll style={props}>{children}</LayoutAll>)
          : show =>
              show &&
              (props => (
                <LayoutContent style={props}>{children}</LayoutContent>
              ))}
      </Transition>
    )
  }

  if (position === 'end') {
    return (
      <Transition
        items={isActive}
        from={{
          left: '100%',
          // left: current ? '100%' : '-100%',
          opacity: 0,
        }}
        enter={{
          left: '0%',
          opacity: 1,
        }}
        leave={{
          left: '-100%',
          opacity: 0,
        }}
        config={config.stiff}
      >
        {area === 'all'
          ? show =>
              show && (props => <LayoutAll style={props}>{children}</LayoutAll>)
          : show =>
              show &&
              (props => (
                <LayoutContent style={props}>{children}</LayoutContent>
              ))}
      </Transition>
    )
  }
  return null
}

export default PanelTransition
