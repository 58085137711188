import React from 'react'
import styled from 'styled-components'

import { ScheduleGroup, ScheduleItem } from 'ui/components'

import { SessionSchedule, SessionScheduleItem } from 'context/SessionData'
import { groupBy } from 'lodash'
import moment from 'moment'

moment.updateLocale('en', {
  relativeTime: {
    h: '1 hour',
    d: '1 day',
  },
})

moment.relativeTimeThreshold('s', 59)
moment.relativeTimeThreshold('m', 59)
moment.relativeTimeThreshold('h', 24)
moment.relativeTimeThreshold('M', 12)

interface ScheduleProps {
  sessionList?: SessionSchedule
}

const Layout = styled.section`
  width: 100%;
  height: auto;
`

const Schedule: React.FC<ScheduleProps> = ({ sessionList }) => {
  let displaySchedule: JSX.Element[] = []
  if (
    sessionList &&
    sessionList.scheduleItems &&
    sessionList.scheduleItems.length > 0
  ) {
    const filteredSessionList = sessionList.scheduleItems.filter(
      (value: SessionScheduleItem) => {
        if (value.performance_end_at) {
          return false
        }

        if (moment.utc().isAfter(moment.utc(value.end_at))) {
          return false
        }
        return true
      },
    )

    const sortedSessionList = filteredSessionList.sort(
      (lhs: SessionScheduleItem, rhs: SessionScheduleItem) => {
        return moment.utc(lhs.start_at).diff(moment.utc(rhs.start_at))
      },
    )

    const groupedSessionList = groupBy(
      sortedSessionList,
      (item: SessionScheduleItem) => {
        return moment(item.start_at)
          .startOf('day')
          .format()
      },
    )

    displaySchedule = Object.keys(groupedSessionList).map((key, _index) => {
      const sessionItems = groupedSessionList[key].map((value: any) => {
        return (
          <ScheduleItem
            sessionName={value.session_name}
            sessionOrganizer={value.created_by.fullName}
            sessionLength={moment
              .duration(moment(value.end_at).diff(moment(value.start_at)))
              .humanize()}
            sessionTime={moment(value.start_at).format('h:mm a')}
            numberOfParticipants={value.attendees.length} //This was hacked from {value.invitees.length}
            to={{ pathname: `/session/${value.id}` }}
            key={value.id}
          />
        )
      })

      let scheduleGroupHeader = key
      if (moment(key).isSame(moment(), 'day')) {
        scheduleGroupHeader = 'Today'
      } else if (
        moment(key).isSame(
          moment()
            .startOf('day')
            .add(1, 'day'),
        )
      ) {
        scheduleGroupHeader = 'Tomorrow'
      } else {
        scheduleGroupHeader = moment(key).format('MMMM Do YYYY')
      }

      return (
        <ScheduleGroup key={key} label={scheduleGroupHeader}>
          {sessionItems}
        </ScheduleGroup>
      )
    })
  }

  return <Layout>{displaySchedule}</Layout>
}

export default Schedule
