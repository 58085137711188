import React from 'react'
import styled from 'styled-components'

interface InputOptionProps {
  label?: string
  value?: any
  disabled?: boolean
  hidden?: boolean
  className?: any
}

const Option = styled.option``

const InputOption: React.FC<InputOptionProps> = ({
  label,
  value,
  disabled = false,
  hidden = false,
  className,
}) => {
  return (
    <Option
      value={value}
      disabled={disabled}
      hidden={hidden}
      className={className}
    >
      {label}
    </Option>
  )
}

export default InputOption
