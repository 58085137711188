import React from 'react'
import styled from 'styled-components'

import { Icon } from 'ui/components'
import theme from 'ui/theme'

interface HeaderNavButtonProps {
  icon?: 'NavMenu' | 'NavClose'
  color?: 'primary' | 'secondary'
  onClick?: () => void
  className?: any
  style?: any
}

const Layout = styled.button`
  appearance: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: ${theme.button.size.circle / 16}rem;
  height: ${theme.button.size.circle / 16}rem;
  border-radius: ${theme.button.size.circle / 16 / 2}rem;
  border: none;
  outline: none;
  background-color: ${({ color }: HeaderNavButtonProps) =>
    color === 'primary' ? theme.color.primary : theme.color.secondary};
  cursor: pointer;
`

const HeaderNavButton: React.FC<HeaderNavButtonProps> = ({
  icon = 'NavMenu',
  color = 'secondary',
  onClick,
  className,
  style,
}) => {
  return (
    <Layout
      type="button"
      color={color}
      className={className}
      style={style}
      onClick={onClick}
    >
      <Icon icon={icon} width={35} height={35} fill="white" />
    </Layout>
  )
}

export default HeaderNavButton
