import React from 'react'

import IconList, { SVGProps } from './IconList'
import { IconTypes } from './IconTypes'

interface IconProps {
  icon: IconTypes
  fill?: any
  width: number
  height: number
  className?: any
}

export const Icon: React.FC<IconProps> = ({
  icon,
  fill = 'black',
  width = 10,
  height = 10,
  className,
}) => {
  const haveSVG = IconList.hasOwnProperty(icon)
  const svg: SVGProps | null = haveSVG ? IconList[icon] : null

  if (!svg) {
    return null
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox={svg.viewbox}
      className={className}
    >
      <g fill={fill}>
        {svg.paths ? <path d={svg.paths} /> : null}
        {svg.points ? <polygon points={svg.points} /> : null}
      </g>
    </svg>
  )
}

export default Icon
