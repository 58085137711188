import React from 'react'

import {
  AuthChooseType,
  AuthContent,
  AuthInstructions,
  AuthLogo,
  AuthPanel,
  AuthScreenContainer,
  AuthToggleButton,
  Instructions,
} from 'ui/components'

import BGImg from 'ui/assets/image/qrs_bg_device-gray.jpg'

import { AuthForgotPasswordForm } from './forms'
import { useAuth } from 'context/AuthenticationContext'

const AuthForgotPasswordScreen: React.FC = () => {
  const { isAuthenticating } = useAuth()

  return (
    <AuthScreenContainer bgImage={BGImg}>
      <AuthContent />

      <AuthPanel>
        <AuthLogo />

        <AuthInstructions>
          Enter your email to receive instructions on how to reset your
          password.
        </AuthInstructions>

        <AuthForgotPasswordForm onSubmit={undefined} />

        <AuthChooseType>
          <Instructions>I remember my password.</Instructions>

          <AuthToggleButton label="Log in" to="/" disabled={isAuthenticating} />
        </AuthChooseType>
      </AuthPanel>
    </AuthScreenContainer>
  )
}

export default AuthForgotPasswordScreen
