import React from 'react'
import styled from 'styled-components'

import { Avatar } from 'ui/components/Avatar'
import UserImg from 'ui/assets/image/user.png'

interface SessionParticipantsProps {
  numberOfParticipants: number
  imageOne?: any
  imageTwo?: any
  className?: any
}

const Layout = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
`

const StyledAvatar = styled(Avatar)`
  position: relative;
  margin-left: -0.625rem;
`

const ImageOne = styled(StyledAvatar)`
  margin-left: 0;
  z-index: 3;
  background-color: #b8b8b8;
`

const ImageTwo = styled(StyledAvatar)`
  z-index: 2;
  background-color: #c7c7c7;
`

const Additional = styled(StyledAvatar)`
  z-index: 1;
`

const SessionUserGroup: React.FC<SessionParticipantsProps> = ({
  numberOfParticipants,
  imageOne = UserImg,
  imageTwo = UserImg,
  className,
}) => {
  const MAX_USERS_SHOWN = 2

  return (
    <Layout className={className}>
      {numberOfParticipants >= 1 && <ImageOne image={imageOne} />}
      {numberOfParticipants >= 2 && <ImageTwo image={imageTwo} />}
      {numberOfParticipants - MAX_USERS_SHOWN >= 1 && (
        <Additional additionalNumber={numberOfParticipants - MAX_USERS_SHOWN} />
      )}
    </Layout>
  )
}

export default SessionUserGroup
