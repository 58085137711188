import React, { useState, useEffect } from 'react'
import useForm from 'react-hook-form'
import { OnSubmit } from 'react-hook-form/dist/types'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { AuthForm, Button, Error, InputText, Instructions } from 'ui/components'
import { useAuth } from 'context/AuthenticationContext'

interface Props {
  onSubmit?: OnSubmit<Record<string, any>>
}

const ForgotPass = styled(Instructions)`
  cursor: pointer;
`

const ErrorMessage = styled(Error)`
  margin: 0.6275rem 0 0;
  padding: 0 1.5rem;
`

const AuthLoginForm: React.FC<Props> = ({ onSubmit }) => {
  const { register, handleSubmit, errors, setError, clearError } = useForm({
    mode: 'onSubmit',
  })

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { login, isAuthenticating, authorizationToken } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (authorizationToken !== undefined) {
      history.push('schedule')
    }
  }, [history, authorizationToken])

  onSubmit = async _data => {
    clearError() //Clear all errors, none of which are front-end errors.
    const loginResponse = await login(email, password)
    if (
      loginResponse.success === false &&
      loginResponse.error &&
      loginResponse.error.length > 0
    ) {
      for (const loginResponseError of loginResponse.error) {
        setError(
          loginResponseError.fieldName,
          'custom',
          loginResponseError.errors[0],
        )
      }
    }
  }

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <InputText
        label="Email"
        placeholder="Email"
        name="email"
        type="text"
        register={register({
          required: 'Email address is required',
          pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' },
        })}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setEmail(e.target.value)
        }
        hasError={errors.email}
        errorMessage={errors.email ? errors.email.message : undefined}
      />

      <InputText
        label="Password"
        placeholder="Password"
        name="password"
        type="password"
        register={register({
          required: 'Password is required',
          min: { value: 8, message: 'Please enter a valid password.' },
          maxLength: { value: 127, message: 'Please enter a valid password.' },
        })}
        onChange={(e: { target: { value: any } }) =>
          setPassword(e.target.value)
        }
        hasError={errors.password}
        errorMessage={errors.password && errors.password.message}
      />

      {errors.general && <ErrorMessage>{errors.general.message}</ErrorMessage>}

      <Button
        type="submit"
        label="Log in"
        size="large"
        color="primary"
        disabled={isAuthenticating}
      />

      <Link to="/forgot-password">
        <ForgotPass>Forgot password?</ForgotPass>
      </Link>
    </AuthForm>
  )
}

export default AuthLoginForm
