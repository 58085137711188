import React from 'react'
import { config } from 'react-spring'
import { Trail } from 'react-spring/renderprops'

import Metric from './Metric'

interface MetricMapperProps {
  items: any
}

const MetricMapper: React.FC<MetricMapperProps> = ({ items }) => {
  return (
    <Trail
      items={items}
      keys={item => item.label}
      from={{ top: '50%', opacity: 0 }}
      to={{ top: '0%', opacity: 1 }}
      config={config.default}
    >
      {item => props => (
        <Metric
          size="large"
          color="light"
          label={item.label}
          value={item.value}
          style={props}
        />
      )}
    </Trail>
  )
}

export default MetricMapper
