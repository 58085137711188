export interface SVGProps {
  viewbox: string
  paths?: string
  points?: string
}

interface IconGuts {
  [key: string]: SVGProps
}

// tslint:disable:max-line-length
const NavBack: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M10.4593169,5.54056551 L7.01927148,8.9131503 L16.0007324,8.9131503 C17.1568582,8.9131503 17.1568582,10.678897 16.0007324,10.678897 L7.02785773,10.678897 L10.3953161,14.1137045 C10.7788711,14.4972683 10.7549143,15.0123385 10.410007,15.3729234 C10.0664943,15.7320503 9.56818765,15.7676436 9.18722941,15.3866854 L4.26416948,10.4636254 C3.91558588,10.1150418 3.91843013,9.56920629 4.23458929,9.21844368 L9.18722941,4.26847805 C10.014249,3.44145847 11.2863364,4.71354592 10.4593169,5.54056551 Z',
}

const NavClose: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M14.3667739,5.63401289 C14.6599584,5.92719745 14.7114738,6.37698125 14.5031012,6.73182487 L14.4398151,6.82605753 L14.3667739,6.90904072 L11.275,10 L14.3667739,13.0917538 C14.7187885,13.4437685 14.7187936,14.0147619 14.3667739,14.3667816 C14.0735893,14.6599662 13.6238055,14.7114815 13.2689619,14.5031089 L13.1747292,14.4398228 L13.0917461,14.3667816 L10,11.275 L6.90903297,14.3667816 C6.55702611,14.7187885 5.986012,14.7187885 5.63400514,14.3667816 C5.34082058,14.0735971 5.28930528,13.6238133 5.49767786,13.2689697 L5.56096393,13.174737 L5.63400514,13.0917538 L8.725,10 L5.63400514,6.90904072 C5.28199829,6.55703386 5.28199829,5.98601975 5.63400514,5.63401289 C5.9271897,5.34082833 6.3769735,5.28931303 6.73181713,5.49768561 L6.82604978,5.56097168 L6.90903297,5.63401289 L10,8.725 L13.0917461,5.63401289 C13.4437607,5.28199824 14.0147542,5.28199317 14.3667739,5.63401289 Z',
}

const NavForward: SVGProps = {
  viewbox: '0 0 10 18',
  points:
    '8.805 8.698 .933 .826 1.497 .262 9.933 8.698 1.497 17.135 .933 16.571',
}

const NavLogout: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M10.2307859,1 C10.6561654,1 11,1.3353093 11,1.75005438 C11,2.16479946 10.6561654,2.50003821 10.2307859,2.50003821 L10.2307859,2.50003821 L3.30767839,2.50003821 C2.8830948,2.50003821 2.53846432,2.83601771 2.53846432,3.25002205 L2.53846432,3.25002205 L2.53846432,16.7500485 C2.53846432,17.1640176 2.88305862,17.5000323 3.30767839,17.5000323 L3.30767839,17.5000323 L10.2307859,17.5000323 C10.6561654,17.5000323 11,17.8352711 11,18.2500162 C11,18.6647612 10.6561654,19 10.2307859,19 L10.2307859,19 L3.30767839,19 C2.03537473,19 1,17.9905095 1,16.7500132 L1,16.7500132 L1,3.25002205 C1,2.00952579 2.03537473,1 3.30767839,1 L3.30767839,1 Z M13.1850717,5.21279604 C13.47463,4.93208375 13.9477508,4.92848919 14.2402925,5.2056405 L14.2402925,5.2056405 L18.7776192,9.49140765 C18.9201521,9.62642234 19,9.80856954 19,9.99992153 C19,10.1913407 18.919415,10.374227 18.7776192,10.5085026 L18.7776192,10.5085026 L14.2402925,14.7942697 C14.0955133,14.9314344 13.9059842,15 13.71642,15 C13.5238725,15 13.3313249,14.9285789 13.1850717,14.7871478 C12.8962855,14.5064355 12.8985317,14.0542925 13.1925475,13.7771412 L13.1925475,13.7771412 L16.4350746,10.7142664 L7.74625329,10.7142664 C7.33357122,10.7142664 7,10.3949545 7,9.99995512 C7,9.60495571 7.33357122,9.28567739 7.74625329,9.28567739 L7.74625329,9.28567739 L16.4350746,9.28567739 L13.1925475,6.22280262 C12.8984966,5.94565131 12.8955133,5.49350833 13.1850717,5.21279604 Z',
}

const NavMenu: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M15.2734192,12.8505662 C15.7712502,12.8505662 16.175,13.2543231 16.175,13.752147 C16.175,14.1667726 15.8933816,14.5212446 15.4951276,14.6248153 L15.3837451,14.6466978 L15.2734192,14.6537278 L4.72658082,14.6537278 C4.22876795,14.6537278 3.825,14.2499599 3.825,13.752147 C3.825,13.3375214 4.10661836,12.9830494 4.50487235,12.8794788 L4.61625492,12.8575962 L4.72658082,12.8505662 L15.2734192,12.8505662 Z M15.2734192,9.1002831 C15.7712502,9.1002831 16.175,9.50404002 16.175,10.0018639 C16.175,10.4164895 15.8933816,10.7709615 15.4951276,10.8745322 L15.3837451,10.8964147 L15.2734192,10.9034447 L4.72658082,10.9034447 C4.22876795,10.9034447 3.825,10.4996768 3.825,10.0018639 C3.825,9.58723834 4.10661836,9.23276634 4.50487235,9.12919567 L4.61625492,9.10731313 L4.72658082,9.1002831 L15.2734192,9.1002831 Z M15.2734192,5.35 C15.7712431,5.35 16.175,5.75374976 16.175,6.25158082 C16.175,6.6662064 15.8933816,7.0206784 15.4951276,7.12424907 L15.3837451,7.14613161 L15.2734192,7.15316164 L4.72658082,7.15316164 C4.22876795,7.15316164 3.825,6.74939369 3.825,6.25158082 C3.825,5.83695524 4.10661836,5.48248325 4.50487235,5.37891258 L4.61625492,5.35703004 L4.72658082,5.35 L15.2734192,5.35 Z',
}

const Add: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M10.0122856,2 L10.1290325,2.00485978 C10.5306955,2.05010633 10.8678084,2.36204433 10.9764609,2.77000989 L11.0003059,2.88352586 L11.0090651,3.007222 L11.0159615,3.37297499 L11.0090651,4.72917718 L11.0076082,8.9929705 L16.9920367,8.99392637 C17.4074139,8.99392637 17.7580221,9.24707802 17.9157253,9.62511329 L17.957121,9.74214212 L17.9861736,9.86589435 L17.9990411,9.98652321 C18.0204459,10.4488905 17.6811052,10.8600506 17.2217938,10.9781755 L17.1046059,11.0014619 L16.9920367,11.0087194 L16.6264155,11.0156166 L15.2701677,11.0087194 L11.0076082,11.0072768 L11.0090651,16.9919869 C11.0090651,17.4642959 10.6826272,17.8481606 10.2441185,17.9641815 L10.1322806,17.9875028 L10.0165173,17.999041 C9.55417286,18.0204469 9.14303309,17.6810894 9.02491407,17.2217553 L9.00162885,17.1045616 L8.99437164,16.9919869 L8.98673332,16.5004357 L8.99437164,15.2701297 L8.9934015,11.0072768 L3.00796327,11.0087194 C2.59258607,11.0087194 2.24197791,10.7555678 2.08427467,10.3775325 L2.04287899,10.2605037 L2.01382641,10.1367514 L2.00095892,10.0161226 C1.97955409,9.55375527 2.31889483,9.14259517 2.77820621,9.0244703 L2.89539411,9.00118393 L3.00796327,8.99392637 L3.49922074,8.98628676 L4.72973637,8.99392637 L8.9934015,8.9929705 L8.99437164,3.007222 C8.99437164,2.59182426 9.24751078,2.24119877 9.62552736,2.08348774 L9.7425504,2.04209 L9.86629651,2.01303599 L9.9659644,2.00182925 L10.0122856,2 Z',
}

const Check: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M19.6687135,3.29233918 C19.2789669,2.90255361 18.647076,2.90255361 18.2572515,3.29233918 L6.29998051,15.2497271 L1.70380117,10.6535478 C1.31405458,10.2637622 0.682163743,10.2638012 0.292339181,10.6535478 C-0.0974463938,11.0432943 -0.0974463938,11.6751852 0.292339181,12.0649708 L5.59424951,17.3668031 C5.98387914,17.7565497 6.61623782,17.7562768 7.0057115,17.3668031 L19.6687135,4.70380117 C20.058499,4.31405458 20.05846,3.68212476 19.6687135,3.29233918 Z',
}

const Close: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M0.607521697,16.3837994 L6.97203472,9.9807136 L0.607521697,3.61620058 C-0.202507232,2.80617165 -0.202507232,1.45612343 0.607521697,0.607521697 C1.45612343,-0.202507232 2.80617165,-0.202507232 3.61620058,0.607521697 L9.9807136,6.97203472 L16.3837994,0.607521697 C17.1938284,-0.202507232 18.5438766,-0.202507232 19.3924783,0.607521697 C20.2025072,1.45612343 20.2025072,2.80617165 19.3924783,3.61620058 L12.9893925,9.9807136 L19.3924783,16.3837994 C20.2025072,17.1938284 20.2025072,18.5438766 19.3924783,19.3924783 C18.5438766,20.2025072 17.1938284,20.2025072 16.3837994,19.3924783 L9.9807136,12.9893925 L3.61620058,19.3924783 C2.80617165,20.2025072 1.45612343,20.2025072 0.607521697,19.3924783 C-0.202507232,18.5438766 -0.202507232,17.1938284 0.607521697,16.3837994 Z',
}

const Delete: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M18.1369369,2.5 L13.4072072,2.5 L13.4072072,1.82432432 C13.4072072,0.810810811 12.5738739,0 11.5828829,0 L8.67747748,0 C7.66396396,0 6.85315315,0.833333333 6.85315315,1.82432432 L6.85315315,2.5 L1.8981982,2.5 C1.51531532,2.5 1.2,2.81531532 1.2,3.1981982 C1.2,3.58108108 1.51531532,3.8963964 1.8981982,3.8963964 L3.31711712,3.8963964 L3.31711712,17.6126126 C3.31711712,18.9414414 4.3981982,20 5.7045045,20 L14.5333333,20 C15.8621622,20 16.9207207,18.9189189 16.9207207,17.6126126 L16.9207207,3.8963964 L18.1144144,3.8963964 C18.4972973,3.8963964 18.8126126,3.58108108 18.8126126,3.1981982 C18.8126126,2.81531532 18.5198198,2.5 18.1369369,2.5 Z M7.7990991,15.8108108 C7.7990991,16.1936937 7.48378378,16.509009 7.1009009,16.509009 C6.71801802,16.509009 6.4027027,16.1936937 6.4027027,15.8108108 L6.4027027,6.68918919 C6.4027027,6.30630631 6.71801802,5.99099099 7.1009009,5.99099099 C7.48378378,5.99099099 7.7990991,6.30630631 7.7990991,6.68918919 L7.7990991,15.8108108 Z M10.8171171,15.8108108 C10.8171171,16.1936937 10.5018018,16.509009 10.1189189,16.509009 C9.73603604,16.509009 9.42072072,16.1936937 9.42072072,15.8108108 L9.42072072,6.68918919 C9.42072072,6.30630631 9.73603604,5.99099099 10.1189189,5.99099099 C10.5018018,5.99099099 10.8171171,6.30630631 10.8171171,6.68918919 L10.8171171,15.8108108 Z M11.9882883,2.5 L8.24954955,2.5 L8.24954955,1.82432432 C8.24954955,1.5990991 8.42972973,1.3963964 8.67747748,1.3963964 L11.5828829,1.3963964 C11.8081081,1.3963964 12.0108108,1.57657658 12.0108108,1.82432432 L12.0108108,2.5 L11.9882883,2.5 Z M13.9027027,15.8108108 C13.9027027,16.1936937 13.5873874,16.509009 13.2045045,16.509009 C12.8216216,16.509009 12.5063063,16.1936937 12.5063063,15.8108108 L12.5063063,6.68918919 C12.5063063,6.30630631 12.8216216,5.99099099 13.2045045,5.99099099 C13.5873874,5.99099099 13.9027027,6.30630631 13.9027027,6.68918919 L13.9027027,15.8108108 Z',
}

const MusicNote: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M6.1208128,12.1417321 C6.50414194,12.1417321 6.86702687,12.2032864 7.19168998,12.3135063 L7.19168998,8.57225264 L7.19168998,6.4305205 L15.0451597,4.28878835 L15.0451597,5.00277314 L15.0451597,6.43074271 L15.0451597,12.4992801 C15.0451597,13.4850369 13.9265052,14.2841309 12.5462981,14.2841309 C11.1663132,14.2841309 10.0474365,13.4850369 10.0474365,12.4992801 C10.0474365,11.5135233 11.1663132,10.7144292 12.5462981,10.7144292 C12.9296272,10.7144292 13.2927344,10.7759835 13.6171753,10.8862033 L13.6171753,7.20917057 L8.61967437,8.57225264 L8.61967437,13.926583 C8.61967437,14.9121176 7.50101993,15.7114339 6.12103502,15.7114339 C4.74082789,15.7114339 3.62217346,14.9123398 3.62217346,13.926583 C3.62195124,12.9408262 4.74060567,12.1417321 6.1208128,12.1417321 Z',
}

const Search: SVGProps = {
  viewbox: '0 0 20 20',
  paths:
    'M9.22648084,3.75 C12.2495694,3.75 14.7029617,6.20339223 14.7029617,9.22648084 C14.7029617,10.3408666 14.3607017,11.4146222 13.7396761,12.3192072 L13.707,12.364 L15.9537802,14.624617 C16.2722528,14.9430896 16.3212486,15.4147599 16.1062483,15.7745934 L16.0410707,15.8697579 L15.962827,15.9581819 C15.7950213,16.1446328 15.5368716,16.25 15.2891986,16.25 C15.0886076,16.25 14.8866165,16.179767 14.7200276,16.0515255 L14.624617,15.9677175 L12.364,13.707 L12.3192072,13.7396761 C11.5729246,14.2520222 10.7115002,14.5746335 9.80711159,14.6718568 L9.50415644,14.695868 L9.22648084,14.7029617 C6.20339223,14.7029617 3.75,12.2495694 3.75,9.22648084 C3.75,6.20339223 6.20339223,3.75 9.22648084,3.75 Z M9.22648084,5.64372822 C7.25102871,5.64372822 5.64372822,7.25506715 5.64372822,9.22648084 C5.64372822,11.1929393 7.26002241,12.8092334 9.22648084,12.8092334 C11.1978945,12.8092334 12.8092334,11.201933 12.8092334,9.22648084 C12.8092334,7.25102871 11.1978945,5.64372822 9.22648084,5.64372822 Z',
}

const IconList: IconGuts = {
  Add,
  Check,
  Close,
  Delete,
  MusicNote,
  NavBack,
  NavClose,
  NavForward,
  NavLogout,
  NavMenu,
  Search,
}

export default IconList
