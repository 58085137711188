import styled from 'styled-components'
import theme from 'ui/theme'

const Input = styled.input`
  appearance: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: ${theme.input.height / 16}rem;
  padding: 0 1.5rem;
  border-radius: 1.5rem;
  border-width: ${theme.ui.border / 16}rem;
  border-style: solid;
  border-color: ${theme.validation.default};
  outline: none;
  background-color: #eff0f0;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  color: ${theme.color.text};
  transition: border-color 100ms ease-in;

  &:focus {
    border-color: ${theme.validation.focus};
  }

  &::placeholder {
    opacity: 0.55;
  }
`

export default Input
