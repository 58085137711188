import React from 'react'
import { FieldError } from 'react-hook-form/dist/types'

import InputWrapper from './InputWrapper'
import Select from './Select'
import theme from 'ui/theme'

interface InputSelectProps {
  label?: string
  name?: any
  register?: any
  onChange?: any
  hasError?: FieldError | undefined
  errorMessage?: string | undefined
  children?: React.ReactNode
  className?: any
  value?: string
  defaultValue?: string
}

const InputSelect: React.FC<InputSelectProps> = ({
  label,
  name,
  register,
  onChange,
  children,
  hasError,
  errorMessage,
  className,
  value,
  defaultValue,
}) => {
  const ErrorStyleInput = {
    borderColor: theme.validation.error,
  }

  return (
    <InputWrapper
      label={label}
      hasError={hasError}
      errorMessage={errorMessage}
      className={className}
    >
      <Select
        name={name}
        ref={register}
        onChange={onChange}
        style={hasError && ErrorStyleInput}
        value={value}
        defaultValue={defaultValue}
      >
        {children}
      </Select>
    </InputWrapper>
  )
}

export default InputSelect
