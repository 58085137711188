import styled from 'styled-components'

const MetricParticipants = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`

export default MetricParticipants
