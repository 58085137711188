import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import theme from 'ui/theme'
import { Icon } from 'ui/components/Icon'
import { SessionUserGroup } from 'ui/components/Session'
import { H3 } from 'ui/components/Typography'

import UserImg from 'ui/assets/image/user.png'

interface SessionScheduleItemProps {
  sessionName?: string
  sessionOrganizer?: string
  sessionLength?: any
  sessionTime?: any
  numberOfParticipants: number
  to?: any
  showTimeline?: boolean
  className?: any
}

const GUTTER = '.9375rem'

const ListItem = styled.li`
  padding-left: ${GUTTER};
`

const TIME_WIDTH = '5.75rem'

const Layout = styled(Link)`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: ${theme.schedule.item.height / 16}rem;
  margin-bottom: 0.9375rem;
  padding: 0 4rem 0 ${TIME_WIDTH};
  list-style: none;
  cursor: pointer;
  color: ${theme.color.text};

  &:visited,
  &:active,
  &:link {
    color: ${theme.color.text.default};
  }
`

const Time = styled.section`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  width: ${TIME_WIDTH};
  height: 100%;
`

const Timeline = styled.span`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 0.09375rem;
  height: 100%;
  background-color: ${theme.color.border};
`

const SessionTime = styled.span`
  padding: 0.125rem 0 0.75rem;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.9375rem;
  line-height: 100%;
`

const Details = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  height: auto;
  padding-bottom: 1.25rem;
  padding-left: ${GUTTER};
`

const SessionName = styled(H3)`
  padding-bottom: 0.3125rem;
`

const SessionMeta = styled.span`
  margin-bottom: 0.9375rem;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 128.57%;
`

const SessionOrganizer = styled.span`
  margin-right: 0.375rem;
`

const SessionLength = styled.span`
  font-weight: 600;
  opacity: 0.5;
`

const Arrow = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 2rem;
  flex-flow: column;
  justify-content: center;
  height: 100%;
`

const ScheduleItem: React.FC<SessionScheduleItemProps> = ({
  sessionName,
  sessionOrganizer,
  sessionLength,
  sessionTime,
  numberOfParticipants,
  to,
  showTimeline = true,
  className,
}) => {
  return (
    <ListItem>
      <Layout to={to} className={className}>
        <Time>
          <SessionTime>{sessionTime}</SessionTime>
          {showTimeline && <Timeline />}
        </Time>

        <Details>
          <SessionName>{sessionName}</SessionName>

          <SessionMeta>
            {sessionOrganizer && (
              <SessionOrganizer>{sessionOrganizer}</SessionOrganizer>
            )}
            <SessionLength>{sessionLength}</SessionLength>
          </SessionMeta>

          <SessionUserGroup
            numberOfParticipants={numberOfParticipants}
            imageOne={UserImg}
            imageTwo={UserImg}
          />
        </Details>

        <Arrow>
          <Icon
            icon="NavForward"
            fill="rgb(152,152,152)"
            width={10}
            height={18}
          />
        </Arrow>
      </Layout>
    </ListItem>
  )
}

export default ScheduleItem
