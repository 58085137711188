import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'

interface ScheduleProps {
  label?: string
  children?: React.ReactNode
  className?: any
}

const Layout = styled.section`
  width: 100%;
  height: auto;
  margin-bottom: 1.75rem;

  &:last-child {
    @media (max-width: ${theme.ui.breakpoint.large}) {
      padding-bottom: ${theme.schedule.item.height / 16}rem;
    }
  }
`

const Label = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5625rem;
  padding: 0 1.5625rem;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 120%;
  color: ${theme.color.text};
  opacity: 0.5;
`

const List = styled.ol`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`

const Schedule: React.FC<ScheduleProps> = ({ label, children, className }) => {
  return (
    <Layout className={className}>
      <Label>{label}</Label>
      <List>{children}</List>
    </Layout>
  )
}

export default Schedule
