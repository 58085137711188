import styled from 'styled-components'
import { H2 } from '../Typography'

import theme from 'ui/theme'

const ProfileTitle = styled(H2)`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    font-size: 1.875rem;
  }
`

export default ProfileTitle
