import React from 'react'
import styled from 'styled-components'

import {
  Button,
  IconDelete,
  ModalActions,
  ModalContent,
  ModalHeader,
} from 'ui/components'

interface Props {
  onCancel?: () => void
  onConfirm?: () => void
}

const StyledIcon = styled(IconDelete)`
  margin-bottom: 2.1875rem;
`

const StyledButton = styled(Button)`
  width: 100%;
`

const ModalSessionConfirmEditCancel: React.FC<Props> = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <ModalContent>
      <StyledIcon />
      <ModalHeader
        title="Discard changes?"
        subtitle="Are you sure you want to discard any changes you've made to this session?"
      />

      <ModalActions>
        <StyledButton
          type="button"
          bgType="border"
          size="default"
          color="primary"
          label="Cancel"
          onClick={onCancel}
        />
        <StyledButton
          type="button"
          bgType="fill"
          size="default"
          color="primary"
          label="Discard changes"
          onClick={onConfirm}
        />
      </ModalActions>
    </ModalContent>
  )
}

export default ModalSessionConfirmEditCancel
