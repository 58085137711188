import styled from 'styled-components'
import theme from 'ui/theme'

const PanelLayout = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 
    '${theme.panel.layout.header}'
    '${theme.panel.layout.content}'
    '${theme.panel.layout.footer}'
    ;
    grid-gap: 0;
    height: 100%;
  }

  position: relative;
  width: 100%;
  height: 100%;
`

export default PanelLayout
