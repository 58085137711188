import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'

interface TabNavItemProps {
  label?: string
  isActive?: boolean
  onClick?: () => void
  className?: any
}

const Layout = styled.span`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 9.375rem;
  height: 100%;
  padding-bottom: 0.1875rem;
  border-bottom-width: 0.1875rem;
  border-bottom-style: solid;
  border-bottom-color: ${({ isActive }: TabNavItemProps) =>
    isActive ? theme.color.primary : 'transparent'};
  background-color: transparent;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: 0.09px;
  color: ${theme.color.white};
  cursor: pointer;
`

const TabNavItem: React.FC<TabNavItemProps> = ({
  label,
  isActive = false,
  onClick,
  className,
}) => {
  return (
    <Layout onClick={onClick} isActive={isActive} className={className}>
      {label}
    </Layout>
  )
}

export default TabNavItem
