import React from 'react'

import { AuthForm, Button, InputText } from 'ui/components'

interface Props {
  onSubmit?: () => void
}

const AuthForgotPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <AuthForm onSubmit={onSubmit}>
      <InputText
        label="Email"
        placeholder="Email"
        name="email"
        type="text"
        hasError={undefined}
        errorMessage="Email address not found"
      />

      <Button
        type="submit"
        label="Submit"
        size="large"
        color="primary"
        disabled={false}
        to="/reset-password"
      />
    </AuthForm>
  )
}

export default AuthForgotPasswordForm
