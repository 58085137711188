import styled from 'styled-components'

const ProfileHeader = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export default ProfileHeader
