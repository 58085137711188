import styled from 'styled-components'
import theme from 'ui/theme'

const H2 = styled.h2`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    font-size: 1.5rem;
  }

  margin: 0;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 100%;
`

export default H2
