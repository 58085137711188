import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import theme from 'ui/theme'

interface ButtonProps {
  type?: 'submit' | 'reset' | 'button'
  bgType?: 'border' | 'fill'
  size: 'default' | 'large' | 'small'
  color?: 'primary' | 'secondary'
  width?: number
  label?: string
  to?: any
  onClick?: () => void
  disabled?: boolean
  className?: any
}

const getBackgroundColor = ({ bgType, color }: ButtonProps) => {
  if (bgType === 'border') {
    return 'transparent'
  }
  if (bgType === 'fill' && color === 'primary') {
    return theme.color.primary
  }
  if (bgType === 'fill' && color === 'secondary') {
    return theme.color.secondary
  }
  return 'transparent'
}

const getBorderColor = ({ color }: ButtonProps) => {
  if (color === 'primary') {
    return theme.color.primary
  }
  if (color === 'secondary') {
    return theme.color.secondary
  }
  return theme.color.primary
}

const Layout = styled.button`
  appearance: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: ${({ width }: ButtonProps) => (width ? `${width / 16}rem` : 'auto')};
  height: ${({ size }: ButtonProps) =>
    size === 'large'
      ? theme.button.size.large / 16
      : size === 'small'
      ? theme.button.size.small / 16
      : theme.button.size.default / 16}rem;
  position: relative;
  margin: 0 0;
  padding: 0 0;
  border-radius: ${({ size }: ButtonProps) =>
    size === 'large'
      ? theme.button.size.large / 16 / 2
      : size === 'small'
      ? theme.button.size.small / 16 / 2
      : theme.button.size.default / 16 / 2}rem;
  border: 0.078125rem solid ${getBorderColor};
  outline: none;
  background-color: ${getBackgroundColor};
  opacity: ${({ disabled }: ButtonProps) => (disabled ? 0.5 : 1)};
  overflow: hidden;
  cursor: pointer;
`

const getLabelColor = ({ bgType, color }: ButtonProps) => {
  if (bgType === 'fill') {
    return theme.color.white
  }
  if (bgType === 'border' && color === 'primary') {
    return theme.color.primary
  }
  if (bgType === 'border' && color === 'secondary') {
    return theme.color.white
  }
  return theme.color.white
}

const Label = styled.span`
  position: relative;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: ${({ size }: ButtonProps) =>
    size === 'small' ? '0.75rem' : '0.875rem'};
  line-height: 100%;
  letter-spacing: 0.09px;
  color: ${getLabelColor};
`

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  label,
  bgType = 'fill',
  size,
  color = 'primary',
  width = 150,
  to,
  onClick,
  disabled = false,
  className,
}) => {
  return (
    <>
      {to ? (
        <Link to={to}>
          <Layout
            type="button"
            bgType={bgType}
            size={size}
            color={color}
            width={width}
            disabled={disabled}
            onClick={onClick}
            className={className}
          >
            <Label bgType={bgType} size={size} color={color}>
              {label}
            </Label>
          </Layout>
        </Link>
      ) : (
        <Layout
          type={type}
          bgType={bgType}
          size={size}
          color={color}
          width={width}
          disabled={disabled}
          onClick={onClick}
          className={className}
        >
          <Label bgType={bgType} size={size} color={color}>
            {label}
          </Label>
        </Layout>
      )}
    </>
  )
}

export default Button
