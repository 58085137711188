import React, { useState } from 'react'
import useForm from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { AuthForm, Button, InputText, Error } from 'ui/components'

import { useAuth } from 'context/AuthenticationContext'

interface AuthCreateAccountFormProps {
  handlePreRegistration?: () => void
}

const ErrorMessage = styled(Error)`
  margin: 0.6275rem 0 0;
  padding: 0 1.5rem;
`

const AuthCreateAccountForm: React.FC<AuthCreateAccountFormProps> = ({
  handlePreRegistration,
}) => {
  const {
    watch,
    register,
    reset,
    handleSubmit,
    errors,
    clearError,
    setError,
  } = useForm({
    mode: 'onSubmit',
  })

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')

  const { registerAccount, isAuthenticating } = useAuth()

  let history = useHistory()

  const onSubmit = async (_data: any) => {
    clearError() // Clear all errors, none of which are front-end errors.
    const registrationResponse = await registerAccount(
      firstName,
      lastName,
      email,
      password,
      confirmationPassword,
      handlePreRegistration !== undefined,
    )

    if (registrationResponse.success === true) {
      if (handlePreRegistration) {
        handlePreRegistration()
        reset()
      } else {
        // route to login screen with the notificationActive state set to true
        history.push('/', { notificationActive: true })
      }
    } else if (
      registrationResponse.success === false &&
      registrationResponse.error &&
      registrationResponse.error.length > 0
    ) {
      for (const registrationError of registrationResponse.error) {
        setError(
          registrationError.fieldName,
          'custom',
          registrationError.errors[0],
        )
      }
    } else {
      //TODO: validation error
    }
  }

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <InputText
        label="First Name"
        placeholder="First Name"
        name="firstName"
        type="text"
        register={register({
          required: 'First Name is required.',
          maxLength: { value: 80, message: 'First name is too long.' },
        })}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setFirstName(e.target.value)
        }
        hasError={errors.firstName}
        errorMessage={errors.firstName && errors.firstName.message}
      />

      <InputText
        label="Last Name"
        placeholder="Last Name"
        name="lastName"
        type="text"
        register={register({
          required: 'Last Name is required.',
          maxLength: { value: 80, message: 'Last name is too long.' },
        })}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setLastName(e.target.value)
        }
        hasError={errors.lastName}
        errorMessage={errors.lastName && errors.lastName.message}
      />

      <InputText
        label="Email"
        placeholder="Email"
        name="email"
        type="text"
        register={register({
          required: 'Email address is required',
          pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' },
        })}
        onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
          setEmail(e.target.value)
        }
        hasError={errors.email}
        errorMessage={errors.email && errors.email.message}
      />

      {!handlePreRegistration && (
        <>
          <InputText
            label="Password"
            placeholder="Password"
            name="password"
            type="password"
            register={register({
              required: 'Password is required',
              min: { value: 8, message: 'Please enter a valid password.' },
              maxLength: {
                value: 127,
                message: 'Please enter a valid password.',
              },
            })}
            onChange={(e: {
              target: { value: React.SetStateAction<string> }
            }) => setPassword(e.target.value)}
            hasError={errors.password}
            errorMessage={errors.password && errors.password.message}
          />

          <InputText
            label="Confirm Password"
            placeholder="Confirm Password"
            name="confirmationPassword"
            type="password"
            register={register({
              required: 'Password confirmation is required',
              min: { value: 8, message: 'Please enter a valid password.' },
              maxLength: {
                value: 127,
                message: 'Please enter a valid password.',
              },
              validate: {
                value: (value: any) => {
                  return value === watch('password')
                    ? true
                    : 'Passwords do not match.'
                },
              },
            })}
            onChange={(e: {
              target: { value: React.SetStateAction<string> }
            }) => setConfirmationPassword(e.target.value)}
            hasError={errors.confirmationPassword}
            errorMessage={
              errors.confirmationPassword && errors.confirmationPassword.message
            }
          />
        </>
      )}

      {errors.general && <ErrorMessage>{errors.general.message}</ErrorMessage>}

      <Button
        type="submit"
        label={handlePreRegistration ? 'Submit' : 'Create account'}
        size="large"
        color="primary"
        disabled={isAuthenticating}
      />
    </AuthForm>
  )
}

export default AuthCreateAccountForm
