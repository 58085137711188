import styled from 'styled-components'

const H3 = styled.h3`
  margin: 0;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 100%;
`

export default H3
