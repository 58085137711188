import React, { useState, useEffect } from 'react'
import { useHistory, RouteComponentProps, match } from 'react-router-dom'
import { config } from 'react-spring'
import { Trail, Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import {
  Button,
  H1,
  Header,
  Loader,
  Metric,
  MetricMapper,
  MetricParticipants,
  Modal,
  Notification,
  Panel,
  ScreenContainer,
  ScreenContent,
  SessionActions,
  SessionDetails,
  SessionMetrics,
  SessionStatus,
  SessionUserGroup,
} from 'ui/components'

import { uniqBy, find, first } from 'lodash'

import { useSession } from 'context/SessionContext'
import { useAuth } from 'context/AuthenticationContext'

import {
  ModalSessionConfirmEditCancel,
  ModalSessionDelete,
  ModalSessionDevices,
} from './Modal'
import {
  PanelComplete,
  PanelEditSession,
  PanelInvite,
  PanelParticipants,
} from './Panels'
import {
  SessionScheduleItem,
  JoinSessionStatus,
  User,
} from 'context/SessionData'
import { useInterval } from 'hooks/useInterval'

import moment from 'moment'

moment.updateLocale('en', {
  relativeTime: {
    h: '1 hour',
    d: '1 day',
  },
})

moment.relativeTimeThreshold('s', 59)
moment.relativeTimeThreshold('m', 59)
moment.relativeTimeThreshold('h', 24)
moment.relativeTimeThreshold('M', 12)

type SessionState = 'inactive' | 'waiting' | 'active' | 'complete'
type SessionModals =
  | 'deleteSession'
  | 'deviceSelection'
  | 'cancelEdit'
  | undefined
type SessionPanels = 'participants' | 'invite' | 'editSession'

interface MatchSessionProp {
  sessionIdentifier?: string
}

interface SessionProps extends RouteComponentProps {
  sessionDevice?: any
  match: match<MatchSessionProp>
  preSessionState?: SessionState
}

const StyledLoader = styled(Loader)`
  display: inline-block;
  top: 0.15625rem;
  margin-right: 0.625rem;
`

const SessionScreen: React.FC<SessionProps> = ({
  sessionDevice = 'QRSC021900005021900005',
  preSessionState,
  match,
}) => {
  const [error, setErrorMessage] = useState<string>('')
  const setError = (error: string) => {
    setErrorMessage(error)
    setTimeout(() => {
      setErrorMessage('')
    }, 5000)
  }

  const clearError = () => {
    setErrorMessage('')
  }

  function pad(number: number) {
    if (number <= 99) {
      return ('0' + number).slice(-2)
    }
    return number
  }

  const { authorizationToken, userIdentifier } = useAuth()
  const { fetchSessionDetail, updateSession, deleteSession } = useSession()
  const [sessionDetail, setSessionDetail] = useState<SessionScheduleItem>()

  useEffect(() => {
    const getSessionDetail = async () => {
      if (
        authorizationToken &&
        userIdentifier &&
        match.params.sessionIdentifier
      ) {
        const result = await fetchSessionDetail(
          authorizationToken,
          match.params.sessionIdentifier,
        )
        setSessionDetail(result.sessionScheduleItem)
      }
    }

    if (!sessionDetail) {
      getSessionDetail()
    }

    if (sessionDetail && sessionDetail.performance_end_at) {
      setSessionState('complete')
    }
  }, [
    authorizationToken,
    fetchSessionDetail,
    match.params.sessionIdentifier,
    sessionDetail,
    userIdentifier,
  ])

  const [elapsedTime, setElapsedTime] = useState('Starting')

  useInterval(() => {
    const getSessionDetail = async () => {
      if (
        authorizationToken &&
        userIdentifier &&
        match.params.sessionIdentifier
      ) {
        const result = await fetchSessionDetail(
          authorizationToken,
          match.params.sessionIdentifier,
        )
        if (result.error) {
          history.push('/schedule', null)
        } else {
          setSessionDetail(result.sessionScheduleItem)
        }
      }
    }

    getSessionDetail()
  }, 10000)

  useInterval(() => {
    if (sessionDetail) {
      const duration = moment.duration(
        moment().diff(moment(sessionDetail.performed_at)),
      )
      const durationDisplay = [
        pad(Math.trunc(duration.asHours())),
        pad(duration.minutes()),
        pad(duration.seconds() >= 0 ? duration.seconds() : 0),
      ].join(':')

      setElapsedTime(durationDisplay)
    }
  }, 1000)

  const [activePanel, setActivePanel] = useState<SessionPanels>('participants')
  const [modalActive, setModalActive] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<SessionModals>(undefined)
  const [sessionState, setSessionState] = useState<SessionState>(
    preSessionState || 'inactive',
  )
  let history = useHistory()

  const metricsInactive = [
    {
      label: 'Start Time',
      value: sessionDetail && moment(sessionDetail.start_at).format('h:mm a'),
    },
    {
      label: 'Session Length',
      value:
        sessionDetail &&
        moment
          .duration(
            moment(sessionDetail.end_at).diff(moment(sessionDetail.start_at)),
          )
          .humanize(),
    },
    {
      label: 'Participants',
      value:
        (sessionDetail &&
          sessionDetail.attendees &&
          sessionDetail.attendees.length) ||
        0,
    },
  ]

  const metricsActive = [
    {
      label: 'Session Length',
      value:
        sessionDetail &&
        moment
          .duration(
            moment(sessionDetail.end_at).diff(moment(sessionDetail.start_at)),
          )
          .humanize(),
    },
    {
      label: 'Time Elapsed',
      value: elapsedTime,
    },
    {
      label: 'Notes Played',
      value: 0,
    },
    {
      label: 'Performing',
      value: first(sessionDetail?.performed_by)?.fullName || 'Live',
    },
  ]

  const duration = moment.duration(
    moment(sessionDetail && sessionDetail.performance_end_at).diff(
      moment(sessionDetail && sessionDetail.performed_at),
    ),
  )

  const metricsComplete = [
    {
      label: 'Total Time',
      value: [
        pad(Math.trunc(duration.asHours())),
        pad(duration.minutes()),
        pad(duration.seconds() >= 0 ? duration.seconds() : 0),
      ].join(':'),
    },
    {
      label: 'Notes Played',
      value: '2500',
    },
    {
      label: 'Participants',
      value: null,
    },
  ]

  const startSession = async () => {
    const numberOfDevices = 1

    if (numberOfDevices > 1) {
      setModalActive(true)
      setModalContent('deviceSelection')
    } else {
      const currentSessionDetail = sessionDetail
      if (authorizationToken && userIdentifier && currentSessionDetail) {
        if (
          sessionDetail &&
          sessionDetail.performed_by &&
          sessionDetail.performed_by.filter(value => {
            return value.userIdentifier === userIdentifier
          }).length >= 1
        ) {
          //Performer Update

          if (!currentSessionDetail.performed_at) {
            currentSessionDetail.performed_at = moment(
              currentSessionDetail.performed_at,
            )
              .add('2', 'seconds')
              .toDate()

            const result = await updateSession(
              authorizationToken,
              userIdentifier,
              currentSessionDetail,
            )

            if (result.status) {
              setSessionState('active')
            } else {
              setError('Unable to start session. Please try again later.')
            }
          } else {
            setSessionState('active')
          }
        } else {
          //Attendee Update
          const currentUser: User = {
            userIdentifier,
          }

          const isAlreadyJoined =
            currentSessionDetail.attendees &&
            currentSessionDetail.attendees.filter(value => {
              return value.userIdentifier === currentUser.userIdentifier
            }).length >= 1

          if (isAlreadyJoined) {
            setSessionState('active')
          } else {
            if (currentSessionDetail.attendees) {
              currentSessionDetail.attendees.push(currentUser)
            } else {
              currentSessionDetail.attendees = [currentUser]
            }

            const result = await updateSession(
              authorizationToken,
              userIdentifier,
              currentSessionDetail,
            )

            if (result.status) {
              setSessionState('active')
            } else {
              setError('Unable to join session. Please try again later.')
            }
          }
        }
      }
    }
  }

  const cancelDeviceSelection = () => {
    setModalActive(false)
    setModalContent(undefined)
  }

  const confirmDeviceSelection = () => {
    // TODO: needs a function to register selection of device
    setModalActive(false)
    setModalContent(undefined)
    setSessionState('active')
  }

  const endSession = async () => {
    const currentSessionDetail = sessionDetail
    if (authorizationToken && userIdentifier && currentSessionDetail) {
      if (
        sessionDetail &&
        sessionDetail.performed_by &&
        sessionDetail.performed_by.filter(value => {
          return value.userIdentifier === userIdentifier
        }).length >= 1
      ) {
        //Performer Update
        currentSessionDetail.performance_end_at = moment()
          .subtract('2', 'seconds')
          .toDate()

        const result = await updateSession(
          authorizationToken,
          userIdentifier,
          currentSessionDetail,
        )

        if (result.status) {
          setSessionState('complete')
        } else {
          setError('Unable to end session. Please try again.')
        }
      } else {
        //Attendee Update
        const currentUser: User = {
          userIdentifier,
        }

        if (currentSessionDetail.attendees) {
          currentSessionDetail.attendees = currentSessionDetail.attendees.filter(
            value => {
              return value.userIdentifier !== currentUser.userIdentifier
            },
          )

          const result = await updateSession(
            authorizationToken,
            userIdentifier,
            currentSessionDetail,
          )

          if (result.status) {
            setSessionState('complete')
          } else {
            setSessionState('complete')
            console.error(
              `Failed to leave session successfully: ${result.error}`,
            )
          }
        }
      }
    }
  }

  const finishSession = () => {
    history.push('/schedule')
  }

  const editSession = () => {
    setActivePanel('editSession')
  }

  const toggleCancelEditSession = () => {
    setModalActive(!modalActive)
    if (modalContent === 'cancelEdit') {
      return setModalContent(undefined)
    }
    setModalContent('cancelEdit')
  }

  const confirmCancelEditSession = () => {
    setModalActive(!modalActive)
    setModalContent(undefined)
  }

  const joinSessionButtonMessage = () => {
    if (
      sessionDetail &&
      sessionDetail.performed_by &&
      sessionDetail.performed_by.filter(value => {
        return value.userIdentifier === userIdentifier
      }).length >= 1
    ) {
      if (sessionDetail.performed_at) {
        return { status: false, message: 'Resume Session' }
      } else {
        return { status: false, message: 'Start Session' }
      }
    } else {
      if (sessionDetail && sessionDetail.performed_at) {
        return { status: false, message: 'Join Session' }
      } else {
        return { status: true, message: 'Waiting for host...' }
      }
    }
  }

  const confirmDeleteSession = async () => {
    if (authorizationToken && sessionDetail && sessionDetail.id) {
      const result = await deleteSession(authorizationToken, sessionDetail.id)
      if (result.status) {
        setModalActive(!modalActive)
        history.push('/schedule', { deleteSuccess: true })
      } else {
        setError('Unable to delete session. Please try again.')
      }
    }
  }

  const toggleDeleteSession = () => {
    setModalActive(!modalActive)
    setModalContent('deleteSession')
  }

  const updateInvitedUsers = async (users: User[]) => {
    if (authorizationToken && userIdentifier && sessionDetail) {
      //TODO: Eventually this goes away when we do invitees instead of attendees
      if (sessionDetail.invitees) {
        sessionDetail.invitees = uniqBy(
          sessionDetail.invitees.concat(users),
          'userIdentifier',
        )
      } else {
        sessionDetail.invitees = users
      }

      const result = await updateSession(
        authorizationToken,
        userIdentifier,
        sessionDetail,
      )

      if (result.status) {
        setActivePanel('participants')
      } else {
        setError('Unable to invite new users, please try again.')
      }
    }
  }

  const joinSessionState: JoinSessionStatus = joinSessionButtonMessage()

  return (
    <>
      <ScreenContainer>
        <Notification
          messageType="error"
          message={error}
          isActive={error !== ''}
          onDismiss={() => clearError()}
        />
        <Header
          type="back"
          backRoute="/schedule"
          backLabel="Back to schedule"
        />

        <ScreenContent>
          <SessionDetails>
            <SessionStatus>
              {sessionState === 'inactive' && 'Session Inactive'}
              {sessionState === 'waiting' && (
                <>
                  <StyledLoader /> Waiting For Host To Start Session
                </>
              )}
              {sessionState === 'active' && 'Session Active'}
              {sessionState === 'complete' && 'Session Complete'}
            </SessionStatus>

            <H1>{sessionDetail && sessionDetail.session_name}</H1>

            <SessionMetrics>
              {(sessionState === 'inactive' || sessionState === 'waiting') && (
                <MetricMapper items={metricsInactive} />
              )}

              {sessionState === 'active' && (
                <MetricMapper items={metricsActive} />
              )}

              {sessionState === 'complete' && (
                <Trail
                  items={metricsComplete}
                  keys={item => item.label}
                  from={{ top: '50%', opacity: 0 }}
                  to={{ top: '0%', opacity: 1 }}
                  config={config.default}
                >
                  {item => props =>
                    item.label === 'Participants' ? (
                      <MetricParticipants style={props}>
                        <SessionUserGroup
                          numberOfParticipants={
                            (sessionDetail &&
                              sessionDetail.attendees &&
                              sessionDetail.attendees.length) ||
                            0
                          }
                        />
                      </MetricParticipants>
                    ) : (
                      <Metric
                        size="large"
                        color="light"
                        label={item.label}
                        value={item.value}
                        style={props}
                      />
                    )}
                </Trail>
              )}
            </SessionMetrics>

            <SessionActions>
              {sessionState === 'inactive' && (
                <>
                  <Button
                    type="button"
                    bgType="fill"
                    size="large"
                    color="primary"
                    label={joinSessionState.message}
                    onClick={startSession}
                    disabled={joinSessionState.status}
                  />
                  {sessionDetail &&
                    sessionDetail.performed_by &&
                    sessionDetail.performed_by.filter(value => {
                      return value.userIdentifier === userIdentifier
                    }).length >= 1 && (
                      <Button
                        type="button"
                        bgType="border"
                        size="large"
                        color="secondary"
                        label="Edit session"
                        onClick={editSession}
                      />
                    )}
                </>
              )}

              {(sessionState === 'waiting' || sessionState === 'active') && (
                <Button
                  type="button"
                  bgType="fill"
                  size="large"
                  color="primary"
                  label="Leave session"
                  onClick={endSession}
                />
              )}

              {sessionState === 'complete' && (
                <Button
                  type="button"
                  bgType="fill"
                  size="large"
                  color="primary"
                  label="Finish"
                  onClick={finishSession}
                />
              )}
            </SessionActions>
          </SessionDetails>
        </ScreenContent>

        <Panel>
          <Transition
            items={sessionState !== 'complete'}
            from={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: '200%', // 200% compensates for 'left: 100%' extra on initial panel transition
              opacity: 0,
            }}
            enter={{ left: '0%', opacity: 1 }}
            leave={{ left: '-100%', opacity: 0 }}
            config={config.stiff}
          >
            {show =>
              show &&
              (props => (
                <div style={props}>
                  <PanelParticipants
                    isActive={activePanel === 'participants'}
                    onAdd={() => setActivePanel('invite')}
                    sessionScheduleItem={sessionDetail}
                  />

                  <PanelInvite
                    isActive={activePanel === 'invite'}
                    onBack={() => setActivePanel('participants')}
                    onInvite={updateInvitedUsers}
                  />

                  <PanelEditSession
                    isActive={activePanel === 'editSession'}
                    onBack={() => setActivePanel('participants')}
                    onSave={() => setActivePanel('participants')}
                    sessionScheduleItem={sessionDetail}
                    onCancelEdit={toggleCancelEditSession}
                    onDelete={toggleDeleteSession}
                  />
                </div>
              ))
            }
          </Transition>

          <Transition
            items={sessionState === 'complete'}
            from={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              left: '200%', // 200% compensates for 'left: -100%' extra on PanelCompleteSummary transition
              opacity: 0,
            }}
            enter={{ left: '0%', opacity: 1 }}
            leave={{ left: '-100%', opacity: 0 }}
            config={config.stiff}
          >
            {show =>
              show &&
              metricsComplete &&
              (props => (
                <div style={props}>
                  <PanelComplete
                    sessionMidiMap={null}
                    sessionDevice={sessionDevice}
                    sessionTotalTime={
                      (
                        find(metricsComplete, {
                          label: 'Total Time',
                        }) || { value: '0:00' }
                      ).value || '0:00'
                    }
                    sessionNotesPlayed={
                      (
                        find(metricsComplete, {
                          label: 'Notes Played',
                        }) || { value: '0' }
                      ).value || '0'
                    }
                    onDownloadMIDI={undefined}
                    onShare={undefined}
                  />
                </div>
              ))
            }
          </Transition>
        </Panel>
      </ScreenContainer>

      <Modal
        isActive={modalActive}
        toggleModal={() => setModalActive(!modalActive)}
      >
        {sessionState === 'inactive' && modalContent === 'deviceSelection' && (
          <ModalSessionDevices
            onCancel={cancelDeviceSelection}
            onSubmit={confirmDeviceSelection}
          />
        )}

        {sessionState === 'inactive' &&
          activePanel === 'editSession' &&
          modalContent === 'deleteSession' && (
            <ModalSessionDelete
              onCancel={() => setModalActive(false)}
              onDelete={confirmDeleteSession}
            />
          )}

        {modalContent === 'cancelEdit' && (
          <ModalSessionConfirmEditCancel
            onCancel={toggleCancelEditSession}
            onConfirm={confirmCancelEditSession}
          />
        )}
      </Modal>
    </>
  )
}

export default SessionScreen
