import styled from 'styled-components'

const Label = styled.label`
  margin-bottom: 0.625rem;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 100%;
  text-align: left;
`

export default Label
