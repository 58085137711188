import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  ButtonIcon,
  PanelContent,
  PanelHeader,
  PanelLayout,
  Schedule,
} from 'ui/components'

import theme from 'ui/theme'
import { useAuth } from 'context/AuthenticationContext'
import { useSession } from 'context/SessionContext'
import { SessionSchedule } from 'context/SessionData'
import moment from 'moment'
import { useInterval } from 'hooks/useInterval'

interface PanelSessionsProps {
  onCreateNewSession?: () => void
  style?: any
  setSessionCount: React.Dispatch<React.SetStateAction<number>>
}

const Layout = styled(PanelLayout)`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    position: absolute;
  }

  position: relative;
`

const PanelSessionsSchedule: React.FC<PanelSessionsProps> = ({
  onCreateNewSession,
  style,
  setSessionCount,
}) => {
  const { authorizationToken, logout } = useAuth()
  const { fetchSessionList } = useSession()

  const [sessionListResponse, setSessionListResponse] = useState<
    SessionSchedule
  >()

  useEffect(() => {
    const getSessionsList = async () => {
      if (!sessionListResponse && authorizationToken) {
        const result = await fetchSessionList(authorizationToken)
        if (result.error) {
          logout()
        } else if (result.sessionSchedule) {
          setSessionListResponse(result.sessionSchedule)
          if (result.sessionSchedule.scheduleItems) {
            const isTodayCount = result.sessionSchedule.scheduleItems.filter(
              value => {
                return (
                  moment(value.start_at).isSame(moment(), 'day') &&
                  moment.utc(value.end_at).isAfter(moment.utc()) &&
                  value.performance_end_at === undefined
                )
              },
            ).length
            setSessionCount(isTodayCount)
          }
        }
      }
    }

    getSessionsList()
  }, [
    sessionListResponse,
    fetchSessionList,
    authorizationToken,
    setSessionCount,
    logout,
  ])

  useInterval(async () => {
    if (authorizationToken) {
      const result = await fetchSessionList(authorizationToken)
      if (result.sessionSchedule) {
        setSessionListResponse(result.sessionSchedule)
        if (result.sessionSchedule.scheduleItems) {
          const isTodayCount = result.sessionSchedule.scheduleItems.filter(
            value => {
              return (
                moment(value.start_at).isSame(moment(), 'day') &&
                moment.utc(value.end_at).isAfter(moment.utc()) &&
                value.performance_end_at === undefined
              )
            },
          ).length
          setSessionCount(isTodayCount)
        }
      }
    }
  }, 5000)

  return (
    <Layout style={style}>
      <PanelHeader title="Sessions">
        <ButtonIcon icon="Add" onClick={onCreateNewSession} />
      </PanelHeader>

      <PanelContent fullWidth hasFooter={false}>
        <Schedule sessionList={sessionListResponse} />
      </PanelContent>
    </Layout>
  )
}

export default PanelSessionsSchedule
