import React from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import theme from 'ui/theme'

interface HeaderNavOverlayProps {
  toggleNav?: () => void
  isActive?: boolean
  className?: any
}

const Layout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  background-color: ${theme.modal.overlay};
  cursor: pointer;
`

const HeaderNavOverlay: React.FC<HeaderNavOverlayProps> = ({
  toggleNav,
  isActive = false,
  className,
}) => {
  return (
    <Transition
      items={isActive}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      config={config.stiff}
    >
      {show =>
        show &&
        (props => (
          <Layout className={className} style={props} onClick={toggleNav} />
        ))
      }
    </Transition>
  )
}

export default HeaderNavOverlay
