import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'

interface AvatarProps {
  type?: 'participant' | 'user'
  image?: any
  additionalNumber?: number
  children?: React.ReactNode
  className?: any
}

const Layout = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${({ type }: AvatarProps) =>
    type === 'participant' ? '2.5rem' : '5.3125rem'};
  height: ${({ type }: AvatarProps) =>
    type === 'participant' ? '2.5rem' : '4.375rem'};
  border-radius: ${({ type }: AvatarProps) =>
    type === 'participant' ? '1.25rem' : '.9375rem'};
  border: 0.0265rem solid ${theme.color.border};
  background-color: ${theme.color.border};
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`

const AdditionalNumber = styled.span`
  position: relative;
  left: -0.125rem;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 100%;
  color: ${theme.color.text};
`

const Avatar: React.FC<AvatarProps> = ({
  type = 'participant',
  image,
  additionalNumber,
  children,
  className,
}) => {
  return (
    <Layout type={type} className={className}>
      {image && <Image src={image} />}
      {type === 'participant' && additionalNumber && (
        <AdditionalNumber>+{additionalNumber}</AdditionalNumber>
      )}
      {children}
    </Layout>
  )
}

export default Avatar
