import React from 'react'
import styled from 'styled-components'

import * as easings from 'd3-ease'
import { Transition } from 'react-spring/renderprops'

import { Text } from '../Typography'
import theme from 'ui/theme'

interface NotificationProps {
  messageType?: 'alert' | 'error' | 'success'
  message?: string
  isActive?: boolean
  onDismiss?: () => void
  className?: any
}

const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${theme.notification.zIndex};
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: ${theme.ui.gutter.small} ${theme.ui.gutter.small} 0;
`

const getBGColor = ({ messageType }: NotificationProps) => {
  if (messageType === 'alert') {
    return theme.notification.color.alert
  }
  if (messageType === 'error') {
    return theme.notification.color.error
  }
  if (messageType === 'success') {
    return theme.notification.color.success
  } else {
    return theme.notification.color.alert
  }
}

const Layout = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    width: 28.125rem;
  }

  width: 100%;
  max-width: 28.125rem;
  height: auto;
  padding: 1.5625rem;
  border-radius: ${theme.ui.radius / 2 / 16}rem;
  background-color: ${getBGColor};
  cursor: pointer;
`

const Message = styled(Text)`
  text-align: center;
  color: white;
`

const Notification: React.FC<NotificationProps> = ({
  messageType = 'alert',
  message,
  isActive = false,
  onDismiss,
  className,
}) => {
  return (
    <Transition
      items={isActive}
      from={{ top: '-3.125rem', opacity: 0 }}
      enter={{ top: '0%', opacity: 1 }}
      leave={{ top: '-3.125rem', opacity: 0 }}
      config={{ duration: 200, easing: easings.easeQuadInOut }}
    >
      {show =>
        show &&
        (props => (
          <Container style={props}>
            <Layout
              messageType={messageType}
              className={className}
              onClick={onDismiss}
            >
              <Message>{message}</Message>
            </Layout>
          </Container>
        ))
      }
    </Transition>
  )
}

export default Notification
