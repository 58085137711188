import styled from 'styled-components'

const ModalActions = styled.footer`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.9375rem;
  width: 100%;
  height: auto;
  margin-top: 2.5rem;
`

export default ModalActions
