import React from 'react'
import { config } from 'react-spring'
import { Spring } from 'react-spring/renderprops'
import styled from 'styled-components'

import theme from 'ui/theme'

interface SessionDetailsProps {
  children?: React.ReactNode
  className?: any
}

const Layout = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-gap: 1.875rem;
  }

  display: grid;
  position: relative;
  grid-auto-flow: row;
  grid-gap: ${theme.ui.gutter.small};
  width: 100%;
  height: auto;
`

const SessionDetails: React.FC<SessionDetailsProps> = ({ children }) => {
  return (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }} config={config.gentle}>
      {props => <Layout style={props}>{children}</Layout>}
    </Spring>
  )
}

export default SessionDetails
