import React, { useState } from 'react'
import styled from 'styled-components'

import { Avatar } from 'ui/components/Avatar'
import { Icon } from 'ui/components/Icon'
import { Text } from 'ui/components/Typography'
import theme from 'ui/theme'

interface SessionUserProps {
  userIdentifier: string
  image?: any
  name?: string
  selected?: boolean
  onClick: (userIdentifier: string, state: boolean) => void
  className?: any
}

const Layout = styled.li`
  display: flex;
  position: relative;
  z-index: 0;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.375rem;
  margin-bottom: 0.9375rem;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`

const UserDetails = styled.div`
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const Name = styled(Text)`
  padding: 0 1.5625rem;
  font-weight: 600;
`

const Toggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.625rem;
  background-color: rgb(239, 239, 239);
`

const ToggleActive = styled(Toggle)`
  background-color: ${theme.color.primary};
`

const SessionUser: React.FC<SessionUserProps> = ({
  userIdentifier,
  image,
  name,
  selected = false,
  onClick,
  className,
}) => {
  const [isSelected, setIsSelected] = useState(selected)
  const handleClick = () => {
    onClick(userIdentifier, !isSelected)
    return setIsSelected(!isSelected)
  }

  return (
    <Layout className={className} onClick={handleClick}>
      <UserDetails>
        <Avatar type="user" image={image} />
        <Name>{name}</Name>
      </UserDetails>

      {isSelected ? (
        <ToggleActive>
          <Icon icon="Check" fill="white" width={12} height={12} />
        </ToggleActive>
      ) : (
        <Toggle />
      )}
    </Layout>
  )
}

export default SessionUser
