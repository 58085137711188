import styled from 'styled-components'
import theme from 'ui/theme'

const Text = styled.p`
  margin: 0 0;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 100%;
  color: ${theme.color.text.default};
`

export default Text
