import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  Button,
  H1,
  H3,
  Header,
  ScreenContainer,
  ScreenContent,
} from 'ui/components'
import theme from 'ui/theme'

interface Props {
  title?: string
  subtitle?: string
}

const StyledScreenContainer = styled(ScreenContainer)`
  min-height: 100vh;
`

const Layout = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-gap: 1.875rem;
  }

  display: grid;
  position: relative;
  grid-auto-flow: row;
  grid-gap: ${theme.ui.gutter.small};
  justify-items: center;
  width: 100%;
`

const StyledH3 = styled(H3)`
  text-align: center;
  color: ${theme.color.white};
`

const NotFoundScreen: React.FC<Props> = ({
  title = 'Error',
  subtitle = 'Try reloading the page or pressing the button below.',
}) => {
  let history = useHistory()

  function goBack() {
    history.goBack()
  }

  return (
    <StyledScreenContainer>
      <Header />

      <ScreenContent>
        <Layout>
          <H1>{title}</H1>
          <StyledH3>{subtitle}</StyledH3>

          <Button
            type="button"
            size="large"
            bgType="fill"
            label="Go back"
            onClick={goBack}
          />
        </Layout>
      </ScreenContent>
    </StyledScreenContainer>
  )
}

export default NotFoundScreen
