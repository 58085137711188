import React from 'react'
import styled from 'styled-components'

import {
  Button,
  Card,
  CardContent,
  H3,
  Icon,
  ProfileActions,
  ProfileContentContainer,
  ProfileHeader,
  ProfileTitle,
} from 'ui/components'
import theme from 'ui/theme'

interface CardDeviceProps {
  deviceName?: string
  serialNumber?: string
}

const DeviceInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
`

const DeviceName = styled(H3)`
  color: ${theme.color.white};
`

const SerialNumber = styled.span`
  margin-top: 0.3125rem;
  font-family: 'Montserrat';
  font-size: 0.875rem;
  line-height: 100%;
  opacity: 0.5;
`

const CardDevice: React.FC<CardDeviceProps> = ({
  deviceName,
  serialNumber,
}) => {
  return (
    <Card>
      <CardContent>
        <DeviceInfo>
          <DeviceName>{deviceName}</DeviceName>
          <SerialNumber>Serial Number: {serialNumber}</SerialNumber>
        </DeviceInfo>

        <Icon
          icon="NavForward"
          fill="rgba(255,255,255,.5)"
          width={10}
          height={18}
        />
      </CardContent>
    </Card>
  )
}

const DeviceList = styled.section`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-template-columns: repeat(2, 1fr);
  }

  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3.125rem;
  width: 100%;
  padding-bottom: 3.125rem;
`

const ProfileDevices: React.FC = () => {
  return (
    <ProfileContentContainer>
      <ProfileHeader>
        <ProfileTitle>Devices</ProfileTitle>

        <ProfileActions>
          <Button
            type="button"
            bgType="fill"
            size="default"
            label="Add Device"
          />
        </ProfileActions>
      </ProfileHeader>

      <DeviceList>
        <CardDevice deviceName="Device Name" serialNumber="QR10001123456788" />
        <CardDevice deviceName="Device Name" serialNumber="QR10001123456789" />
      </DeviceList>
    </ProfileContentContainer>
  )
}

export default ProfileDevices
