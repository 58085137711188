import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  AuthChooseType,
  AuthContent,
  AuthInstructions,
  AuthLogo,
  AuthPanel,
  AuthScreenContainer,
  AuthToggleButton,
  Instructions,
  Notification,
} from 'ui/components'
import theme from 'ui/theme'

// import BGImg from 'ui/assets/image/qrs_bg_device-color.jpg'
import BGImg from 'ui/assets/image/qrs_bg_device-gray.jpg'

import { AuthLoginForm } from './forms'
import { useAuth } from 'context/AuthenticationContext'

const AuthLoginScreen: React.FC = () => {
  let location = useLocation()
  let history = useHistory()
  const [notificationActive, setNotificationActive] = useState<boolean>(
    location.state || false,
  )
  const { isAuthenticating } = useAuth()

  useEffect(() => {
    if (notificationActive) {
      history.replace('', null)
      setTimeout(() => {
        setNotificationActive(false)
      }, 5000)
    }
  }, [history, notificationActive])

  return (
    <AuthScreenContainer bgImage={BGImg}>
      <Notification
        messageType="success"
        message="Account created successfully! Please log in."
        isActive={notificationActive}
        onDismiss={() => setNotificationActive(!notificationActive)}
      />

      <AuthContent />

      <AuthPanel className="auth-panel">
        <AuthLogo />
        <AuthInstructions>
          Log in with your {theme.appName} credentials.
        </AuthInstructions>

        <AuthLoginForm />

        <AuthChooseType>
          <Instructions>Don't have an account?</Instructions>
          <AuthToggleButton
            label="Create account"
            to="/create-account"
            disabled={isAuthenticating}
          />
        </AuthChooseType>
      </AuthPanel>
    </AuthScreenContainer>
  )
}

export default AuthLoginScreen
