import React from 'react'
import styled from 'styled-components'

import { H1 } from '../Typography'
import theme from 'ui/theme'

const Content = styled.section`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-area: ${theme.ui.layout.content};
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    width: auto;
    height: 100%;
    padding: 0 4.0625rem 7.1875rem 0.9375rem;
    position: relative;
  }

  display: none;
`

const Tagline = styled(H1)`
  max-width: 25.625rem;
  line-height: 131.57%;
  color: white;
`

const Emphasis = styled.span`
  color: ${theme.color.primary};
`

const AuthContent: React.FC = () => {
  return (
    <Content>
      <Tagline>
        Mobilize <Emphasis>your music</Emphasis>, instruments, work, and tools.
      </Tagline>
    </Content>
  )
}

export default AuthContent
