import styled from 'styled-components'
import theme from 'ui/theme'

const SessionMetrics = styled.div`
  @media (min-width: ${theme.ui.breakpoint.xlarge}) {
    grid-template-columns: repeat(3, 9.375rem) auto;
  }

  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-gap: 3.125rem;
  }

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${theme.ui.gutter.small} 1.875rem;
  width: 100%;
  height: auto;
`

export default SessionMetrics
