import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'

interface AuthPanelProps {
  children?: React.ReactNode
  className?: any
  innerStyle?: any
}

const Layout = styled.section`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-area: ${theme.ui.layout.panel};
    grid-column: 2;
    grid-row: 1 / span 2;
    width: 28.125rem;
    height: 100%;
  }

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0 0;
  overflow: hidden;
`

const Inner = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${theme.ui.breakpoint.medium}) {
    padding: 3.125rem 3.125rem;
  }

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3.125rem 1.5rem;
  border-radius: ${theme.ui.radius / 16}rem;
  background-color: ${theme.color.white};
  overflow: scroll;
`

const AuthPanel: React.FC<AuthPanelProps> = ({
  children,
  className,
  innerStyle,
}) => {
  return (
    <Layout className={className}>
      <Inner style={innerStyle}>{children}</Inner>
    </Layout>
  )
}

export default AuthPanel
