import styled from 'styled-components'
import theme from 'ui/theme'

const PanelFooter = styled.footer`
  grid-area: ${theme.panel.layout.footer};
  display: flex;
  position: absolute;
  bottom: 0;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${theme.panel.footer.height};
  padding: 1.5625rem;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
`

export default PanelFooter
