import styled from 'styled-components'
import theme from 'ui/theme'

const ProfileContent = styled.section`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-template-columns: repeat(3, 1fr);
  }

  display: grid;
  grid-template-columns: 100%;
  grid-gap: 3.125rem;
  width: 100%;
  padding-bottom: 3.125rem;
`

export default ProfileContent
