import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'

import UserImg from 'ui/assets/image/user.png'

interface ProfileAvatarProps {
  size?: 'large' | 'small'
  image?: any
  altText?: string
  className?: any
}

const SIZE_LARGE = '6.25rem'
const SIZE_SMALL = '3.125rem'

const Layout = styled.div`
  display: flex;
  flex-shrink: 0;
  position: relative;
  width: ${({ size }: ProfileAvatarProps) =>
    size === 'large' ? SIZE_LARGE : SIZE_SMALL};
  height: ${({ size }: ProfileAvatarProps) =>
    size === 'large' ? SIZE_LARGE : SIZE_SMALL};
  border-radius: 50%;
  border: 0.5px solid ${theme.color.border};
  background-color: ${theme.color.border};
  overflow: hidden;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  size = 'large',
  image = UserImg,
  altText,
  className,
}) => {
  return (
    <Layout size={size} className={className}>
      <Image src={image} alt={altText} />
    </Layout>
  )
}

export default ProfileAvatar
