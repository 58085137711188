import React from 'react'
import styled from 'styled-components'

import {
  Card,
  CardContent,
  CardMedia,
  Filter,
  H3,
  InputOption,
  ProfileActions,
  ProfileContentContainer,
  ProfileDateSection,
  ProfileHeader,
  ProfileTitle,
  Search,
  SessionUserGroup,
  Text,
} from 'ui/components'
import theme from 'ui/theme'

import MIDIFPO from 'ui/assets/image/MIDI.png'

interface CardFileProps {
  sessionName?: string
  sessionDate?: string
  sessionParticipants?: any
  sessionMIDI?: any
}

const MIDIMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const FileDetails = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  top: 0.0625rem;
  padding-right: 0.9375rem;
  color: ${theme.color.white};
`

const SessionName = styled(H3)``

const SessionDate = styled(Text)`
  margin-top: 0.5rem;
  letter-spacing: 0;
  color: ${theme.color.white};
  opacity: 0.5;
`

const CardFile: React.FC<CardFileProps> = ({
  sessionName,
  sessionDate,
  sessionParticipants,
  sessionMIDI,
}) => {
  return (
    <Card color="light">
      <CardMedia>
        <MIDIMap src={sessionMIDI} />
      </CardMedia>

      <CardContent>
        <FileDetails>
          <SessionName>{sessionName}</SessionName>
          <SessionDate>{sessionDate}</SessionDate>
        </FileDetails>
        <SessionUserGroup numberOfParticipants={sessionParticipants} />
      </CardContent>
    </Card>
  )
}

const ProfileFiles: React.FC = () => {
  return (
    <ProfileContentContainer>
      <ProfileHeader>
        <ProfileTitle>Files</ProfileTitle>
        <ProfileActions>
          <Filter label="Sort by">
            <InputOption label="Newest" />
          </Filter>
          <Search />
        </ProfileActions>
      </ProfileHeader>

      <ProfileDateSection label="December 2019">
        <CardFile
          sessionName="Session Name"
          sessionDate="12/30/2019"
          sessionParticipants={3}
          sessionMIDI={MIDIFPO}
        />
        <CardFile
          sessionName="Session Name"
          sessionDate="12/15/2019"
          sessionParticipants={3}
          sessionMIDI={MIDIFPO}
        />
        <CardFile
          sessionName="Session Name"
          sessionDate="12/10/2019"
          sessionParticipants={3}
          sessionMIDI={MIDIFPO}
        />
        <CardFile
          sessionName="Session Name"
          sessionDate="12/1/2019"
          sessionParticipants={3}
          sessionMIDI={MIDIFPO}
        />
      </ProfileDateSection>

      <ProfileDateSection label="November 2019">
        <CardFile
          sessionName="Session Name"
          sessionDate="12/30/2019"
          sessionParticipants={3}
          sessionMIDI={MIDIFPO}
        />
        <CardFile
          sessionName="Session Name"
          sessionDate="12/15/2019"
          sessionParticipants={3}
          sessionMIDI={MIDIFPO}
        />
      </ProfileDateSection>
    </ProfileContentContainer>
  )
}

export default ProfileFiles
