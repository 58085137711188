import React from 'react'
import { FieldError } from 'react-hook-form/dist/types'

import Input from './Input'
import InputWrapper from './InputWrapper'
import theme from 'ui/theme'

interface InputTextProps {
  label?: string
  placeholder?: string
  name?: any
  type?: string
  register?: any
  onChange?: any
  value?: string
  defaultValue?: string
  hasError?: FieldError | undefined
  errorMessage?: string | undefined
  className?: any
  autoComplete?: string
}

const InputText: React.FC<InputTextProps> = ({
  label,
  placeholder,
  name,
  type = 'text',
  register,
  onChange,
  hasError,
  errorMessage = 'Error.',
  className,
  value,
  defaultValue,
  autoComplete,
}) => {
  const ErrorStyleInput = {
    borderColor: theme.validation.error,
  }

  return (
    <InputWrapper
      label={label}
      hasError={hasError}
      errorMessage={errorMessage}
      className={className}
    >
      <Input
        type={type}
        placeholder={placeholder}
        name={name}
        ref={register}
        onChange={onChange}
        style={hasError && ErrorStyleInput}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
      />
    </InputWrapper>
  )
}

export default InputText
