import styled from 'styled-components'

const AuthForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.25rem;
  justify-items: center;
  width: 100%;
  height: auto;
`

export default AuthForm
