import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'
import BG_DEFAULT from '../../assets/image/qrs_bg_sessions.jpg'

interface ScreenContainerProps {
  bgImage?: string
  children?: React.ReactNode
  className?: any
}

const Layout = styled.main`
  @media(min-width: ${theme.ui.breakpoint.large}) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      '${theme.ui.layout.header} ${theme.ui.layout.panel}'
      '${theme.ui.layout.content} ${theme.ui.layout.panel}';
    grid-gap: 0 3.125rem;
    height: 100vh;
  }

  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
`

const ScreenContainer: React.FC<ScreenContainerProps> = ({
  bgImage = BG_DEFAULT,
  children,
  className,
}) => {
  return (
    <Layout
      role="main"
      className={className}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      {children}
    </Layout>
  )
}

export default ScreenContainer
