import styled from 'styled-components'
import theme from 'ui/theme'

const SessionActions = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-gap: 1.9375rem;
  }

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 9.375rem;
  grid-gap: ${theme.ui.gutter.small};
`

export default SessionActions
