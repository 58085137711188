import styled from 'styled-components'
import theme from 'ui/theme'

const SessionStatus = styled.span`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    position: absolute;
    top: -3rem;
    left: 0;
  }

  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 100%;
  color: ${theme.color.white};
  opacity: 0.4;
`

export default SessionStatus
