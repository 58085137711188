import React from 'react'
import styled from 'styled-components'

import { H2 } from '../Typography'
import theme from 'ui/theme'

interface ModalHeaderProps {
  title?: string
  subtitle?: string
  className?: any
}

const Layout = styled.header`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Title = styled(H2)`
  line-height: 117%;
  text-align: center;
`

const Subtitle = styled.span`
  margin-top: 1.5625rem;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 135%;
  text-align: center;
  color: ${theme.color.text.light};
`

const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  subtitle,
  className,
}) => {
  return (
    <Layout className={className}>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Layout>
  )
}

export default ModalHeader
