import React from 'react'
import styled from 'styled-components'

import { Input } from '../Input'
import SearchButton from './SearchButton'
import theme from 'ui/theme'

interface SearchProps {
  placeholder?: string
  hasSubmitButton?: boolean
  onSubmit?: () => void
  className?: any
}

const Layout = styled.div`
  position: relative;
  width: auto;
  height: ${theme.input.height / 16}rem;
`

const SUBMIT_BUTTON_GUTTER = 11 * 2
const INPUT_PADDING = theme.button.size.icon + SUBMIT_BUTTON_GUTTER

const InputWithButton = styled(Input)`
  padding-right: ${INPUT_PADDING / 16}rem;
`

const Search: React.FC<SearchProps> = ({
  placeholder = 'Search',
  hasSubmitButton = true,
  onSubmit,
  className,
}) => {
  return (
    <Layout className={className}>
      {hasSubmitButton ? (
        <>
          <SearchButton onClick={onSubmit} />
          <InputWithButton placeholder={placeholder} />
        </>
      ) : (
        <Input placeholder={placeholder} />
      )}
    </Layout>
  )
}

export default Search
