import React from 'react'
import styled from 'styled-components'

import {
  ActivityPost,
  ProfileContentContainer,
  ProfileHeader,
  ProfileTitle,
} from 'ui/components'

const StyledProfileContentContainer = styled(ProfileContentContainer)`
  justify-items: center;
`

const ActivityLayout = styled.div`
  max-width: 40.3125rem;
  width: 100%;
`

const ActivityFeed = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1.5625rem;
  width: 100%;
  margin-top: 3.125rem;
  padding-bottom: 3.125rem;
`

const ProfileActivity: React.FC = () => {
  return (
    <StyledProfileContentContainer>
      <ActivityLayout>
        <ProfileHeader>
          <ProfileTitle>Activity</ProfileTitle>
        </ProfileHeader>

        <ActivityFeed>
          <ActivityPost
            username="User Name"
            datePosted="1/1/2020"
            timePosted="12:00 pm"
            message="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec blandit augue vel nulla elementum, vel facilisis purus condimentum."
          />
          <ActivityPost
            username="User Name"
            datePosted="12/31/2019"
            timePosted="2:00 pm"
          />
        </ActivityFeed>
      </ActivityLayout>
    </StyledProfileContentContainer>
  )
}

export default ProfileActivity
