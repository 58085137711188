import React from 'react'

import {
  AuthContent,
  AuthForm,
  AuthInstructions,
  AuthLogo,
  AuthPanel,
  AuthScreenContainer,
  Button,
  InputText,
} from 'ui/components'

import BGImg from 'ui/assets/image/qrs_bg_device-gray.jpg'

interface Props {
  onSubmit?: () => void
}

const AuthResetPasswordScreen: React.FC<Props> = ({ onSubmit }) => {
  return (
    <AuthScreenContainer bgImage={BGImg}>
      <AuthContent />

      <AuthPanel>
        <AuthLogo />
        <AuthInstructions>Reset your password below.</AuthInstructions>

        <AuthForm onSubmit={onSubmit}>
          <InputText
            label="New Password"
            placeholder="New Password"
            name="password"
            type="password"
            hasError={undefined}
          />

          <InputText
            label="Confirm New Password"
            placeholder="Confirm New Password"
            name="confirm_password"
            type="password"
            hasError={undefined}
          />

          <Button
            type="submit"
            label="Reset password"
            size="large"
            color="primary"
            disabled={false}
            to="/"
          />
        </AuthForm>
      </AuthPanel>
    </AuthScreenContainer>
  )
}

export default AuthResetPasswordScreen
