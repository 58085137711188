import styled from 'styled-components'
import theme from 'ui/theme'

const CardMedia = styled.div`
  width: 100%;
  height: 11.875rem;
  padding: 0.625rem 0;
  background-color: ${theme.color.white};
`

export default CardMedia
