import React from 'react'
import { config } from 'react-spring'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'

import {
  Button,
  H2,
  IconDownload,
  PanelActions,
  PanelContent,
  PanelFooter,
  PanelLayout,
} from 'ui/components'

interface Props {
  isActive?: boolean
  onBack?: () => void
}

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Message = styled(H2)`
  margin-top: 1.875rem;
  line-height: 117%;
  text-align: center;
`

const PanelCompleteDownload: React.FC<Props> = ({
  isActive = false,
  onBack,
}) => {
  return (
    <Transition
      items={isActive}
      from={{ position: 'absolute', left: '100%', opacity: 0 }}
      enter={{ left: '0%', opacity: 1 }}
      leave={{ left: '100%', opacity: 0 }}
      config={config.stiff}
    >
      {show =>
        show &&
        (props => (
          <PanelLayout style={props}>
            <PanelContent>
              <Content>
                <IconDownload size={100} />
                <Message>
                  Downloading
                  <br />
                  session MIDI!
                </Message>
              </Content>
            </PanelContent>

            <PanelFooter>
              <PanelActions>
                <Button
                  type="button"
                  bgType="fill"
                  size="default"
                  color="primary"
                  label="Back"
                  onClick={onBack}
                />
              </PanelActions>
            </PanelFooter>
          </PanelLayout>
        ))
      }
    </Transition>
  )
}

export default PanelCompleteDownload
