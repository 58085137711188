import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { config } from 'react-spring'
import { Spring } from 'react-spring/renderprops'
import styled from 'styled-components'

import { ButtonBack, Icon } from 'ui/components'
import { Logo } from '../Logo'
import theme from 'ui/theme'

import HeaderNav from './HeaderNav'
import HeaderNavButton from './HeaderNavButton'
import HeaderNavItem from './HeaderNavItem'
import HeaderNavOverlay from './HeaderNavOverlay'

import { useAuth } from 'context/AuthenticationContext'

interface HeaderProps {
  type?: 'default' | 'back'
  backRoute?: string
  backLabel?: string
  performLogout?: () => void
}

const Layout = styled.header`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    padding: 4.0625rem 0.9375rem 4.0625rem 3.125rem;
  }

  grid-area: ${theme.ui.layout.header};
  display: flex;
  position: relative;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 2.5rem 1.25rem;
`

const CloseButton = styled(HeaderNavButton)`
  position: absolute;
  top: 4.0625rem;
  right: -${theme.button.size.circle / 16 / 2}rem;
`

const NavContainer = styled.div`
  width: 100%;
`

const StyledLogo = styled(Logo)`
  width: 10.9375rem;
  margin-bottom: 2.5rem;
`

const Logout = styled.span`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1.25rem;
  justify-items: start;
  align-items: center;
  width: 100%;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: 0.14px;
  color: ${theme.color.text.default};
  opacity: 0.75;
  cursor: pointer;
`

const Header: React.FC<HeaderProps> = ({
  type = 'default',
  backRoute = '/schedule',
  backLabel = 'Back',
  performLogout,
}) => {
  const [navActive, setNavActive] = useState<boolean>(false)
  const { logout, authorizationToken } = useAuth()
  const history = useHistory()

  useEffect(() => {
    if (authorizationToken === undefined) {
      history.replace('')
    }
  }, [history, authorizationToken])

  performLogout = () => {
    logout()
  }

  return (
    <Layout role="banner">
      {type === 'default' && (
        <Spring
          from={{ position: 'relative', top: '-1.5625rem', opacity: 0 }}
          to={{ position: 'relative', top: '0rem', opacity: 1 }}
          config={config.stiff}
        >
          {props => (
            <HeaderNavButton
              color="primary"
              onClick={() => setNavActive(!navActive)}
              style={props}
            />
          )}
        </Spring>
      )}
      {type === 'back' && (
        <Spring
          from={{ position: 'relative', top: '-1.5625rem', opacity: 0 }}
          to={{ position: 'relative', top: '0rem', opacity: 1 }}
          config={config.stiff}
        >
          {props => (
            <ButtonBack to={backRoute} label={backLabel} style={props} />
          )}
        </Spring>
      )}

      <HeaderNav isActive={navActive}>
        <CloseButton
          icon="NavClose"
          color="primary"
          onClick={() => setNavActive(!navActive)}
        />
        <NavContainer>
          <Link to="/">
            <StyledLogo type="color" />
          </Link>

          <HeaderNavItem label="Sessions" to="/schedule" />
          <HeaderNavItem label="My Profile" to="/profile" />
          <HeaderNavItem
            type="download"
            label="Privacy Policy"
            to="/legal/QRS-Connect_Privacy_Policy.pdf"
          />
          <HeaderNavItem
            type="download"
            label="Terms of Use"
            to="/legal/QRS-Connect_Terms_of_Use.pdf"
          />
        </NavContainer>

        <Logout onClick={logout}>
          <Icon
            icon="NavLogout"
            width={20}
            height={20}
            fill={theme.color.text.default}
          />
          Logout
        </Logout>
      </HeaderNav>

      <HeaderNavOverlay
        toggleNav={() => setNavActive(!navActive)}
        isActive={navActive}
      />
    </Layout>
  )
}

export default Header
