import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import theme from 'ui/theme'

interface CardProps {
  to?: any
  color?: 'dark' | 'light'
  children?: React.ReactNode
  className?: any
}

const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 1.25rem;
  background-color: ${({ color }: CardProps) =>
    color === 'dark' ? theme.color.background : theme.color.white};
  box-shadow: 0px -0.125rem 0.15rem 0px rgba(0, 0, 0, 0.04),
    0px 0.0625rem 0.15rem 0px rgba(0, 0, 0, 0.04),
    0px 0.1625rem 0.38125rem 0.0625rem rgba(0, 0, 0, 0.07),
    0px 0.33125rem 0.775rem 0px rgba(0, 0, 0, 0.05),
    0px 0.6875rem 1.25rem -0.5rem rgba(0, 0, 0, 0.05),
    0px 1.875rem 2.9125rem -0.5rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const Card: React.FC<CardProps> = ({
  to,
  color = 'dark',
  children,
  className,
}) => {
  return to ? (
    <Link to={to}>
      <Layout color={color} className={className}>
        {children}
      </Layout>
    </Link>
  ) : (
    <Layout color={color} className={className}>
      {children}
    </Layout>
  )
}

export default Card
