import React from 'react'
import styled from 'styled-components'

import { Card, CardMedia, ProfileAvatar } from 'ui/components'
import theme from 'ui/theme'

import MIDIFPO from 'ui/assets/image/MIDI.png'

interface ActivityPostProps {
  username?: string
  userImg?: any
  datePosted?: string
  timePosted?: string
  message?: string
  media?: any
}

const Media = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const PostContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 1.25rem 1.25rem;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
  background-color: ${theme.color.background};
`

const PostMeta = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: white;
`

const Info = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 0.75rem;
  color: white;
`

const Username = styled.span`
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 100%;
`

const Timestamp = styled.span`
  margin-top: 0.3rem;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.75rem;
  opacity: 0.75;
`

const PostMessage = styled.p`
  width: 100%;
  margin: 0.9375rem 0 0 0;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 142%;
  color: white;
  opacity: 0.75;
`

const ActivityPost: React.FC<ActivityPostProps> = ({
  username,
  userImg,
  datePosted,
  timePosted,
  message,
  media = MIDIFPO,
}) => {
  return (
    <Card color="light">
      {media && (
        <CardMedia>
          <Media src={media} />
        </CardMedia>
      )}

      <PostContent>
        <PostMeta>
          <ProfileAvatar size="small" image={userImg} />

          <Info>
            <Username>{username}</Username>
            <Timestamp>
              {datePosted}, {timePosted}
            </Timestamp>
          </Info>
        </PostMeta>

        {message && <PostMessage>{message}</PostMessage>}
      </PostContent>
    </Card>
  )
}

export default ActivityPost
