import React from 'react'
import styled from 'styled-components'

interface TabNavProps {
  children?: React.ReactNode
  className?: any
}

const Layout = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 3.5625rem;
`

const TabNav: React.FC<TabNavProps> = ({ children, className }) => {
  return (
    <Layout role="navigation" className={className}>
      {children}
    </Layout>
  )
}

export default TabNav
