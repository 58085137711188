import React from 'react'
import styled from 'styled-components'

import { H1, H3, ScreenContainer, ScreenContent } from 'ui/components'
import theme from 'ui/theme'

const StyledScreenContainer = styled(ScreenContainer)`
  min-height: 100vh;
`

const Layout = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    grid-gap: 1.875rem;
  }

  display: grid;
  position: relative;
  grid-auto-flow: row;
  grid-gap: ${theme.ui.gutter.small};
  justify-items: center;
  width: 100%;
`

const StyledH3 = styled(H3)`
  text-align: center;
  color: ${theme.color.white};
`

const WalkthroughScreen: React.FC = () => {
  return (
    <StyledScreenContainer>
      <ScreenContent>
        <Layout>
          <H1>Walkthrough</H1>
          <StyledH3>Content coming Monday</StyledH3>
        </Layout>
      </ScreenContent>
    </StyledScreenContainer>
  )
}

export default WalkthroughScreen
