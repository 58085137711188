import styled from 'styled-components'
import theme from 'ui/theme'

const H1 = styled.h1`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    font-size: 2.375rem;
    line-height: 100%;
  }

  margin: 0;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 125%;
  color: ${theme.color.white};
`

export default H1
