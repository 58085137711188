import React from 'react'
import styled from 'styled-components'
import theme from 'ui/theme'

interface MetricProps {
  size?: 'large' | 'small'
  color?: 'dark' | 'light'
  label?: string
  value?: any
  className?: any
  style?: any
}

const Layout = styled.div`
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: auto;
  color: ${({ color }: MetricProps) =>
    color === 'dark' ? theme.color.text : theme.color.white};
`

const Label = styled.span`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    font-size: ${({ size }: MetricProps) =>
      size === 'large' ? '.9375rem' : '.75rem'};
  }

  margin-bottom: ${({ size }: MetricProps) =>
    size === 'large' ? '.5rem' : '.3125rem'};
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: ${({ size }: MetricProps) =>
    size === 'large' ? '.875rem' : '.75rem'};
  line-height: 133%;
  color: inherit;
`

const Value = styled.span`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    font-size: ${({ size }: MetricProps) =>
      size === 'large' ? '1.5rem' : '.1.125rem'};
  }

  font-family: 'Montserrat';
  font-weight: ${({ size }: MetricProps) => (size === 'large' ? 600 : 500)};
  font-size: ${({ size }: MetricProps) =>
    size === 'large' ? '1.5rem' : '.1.125rem'};
  line-height: 100%;
  color: inherit;
`

const Metric: React.FC<MetricProps> = ({
  size = 'large',
  color = 'light',
  label,
  value,
  className,
  style,
}) => {
  return (
    <Layout color={color} className={className} style={style}>
      <Label size={size}>{label}</Label>
      <Value size={size}>{value}</Value>
    </Layout>
  )
}

export default Metric
