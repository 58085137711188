import React from 'react'
import styled from 'styled-components'

import { Button } from '../Button'

interface Props {
  label?: string
  onClick?: () => void
  to?: any
  disabled?: boolean
}

const StyledButton = styled(Button)`
  margin-top: 0.9375rem;
`

const AuthToggleButton: React.FC<Props> = ({
  label,
  onClick,
  to,
  disabled,
}) => {
  return (
    <StyledButton
      bgType="border"
      size="small"
      color="primary"
      width={150}
      label={label}
      onClick={onClick}
      to={to}
      disabled={disabled}
    />
  )
}

export default AuthToggleButton
