import React from 'react'
import styled from 'styled-components'

import { Avatar } from 'ui/components/Avatar'
import { Icon } from 'ui/components/Icon'
import { Text } from 'ui/components/Typography'
import theme from 'ui/theme'

interface SessionParticipantProps {
  image?: any
  name?: string
  isActive?: boolean
  className?: any
}

const Layout = styled.li`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4.375rem;
  margin-bottom: 0.9375rem;

  &:last-child {
    margin-bottom: 0;
  }
`
const PerformingBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 2rem;
  height: 1.9375rem;
  background-color: ${theme.color.primary};
  border-top-left-radius: 0.5rem;
`

const Meta = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  margin-left: 1.5625rem;
`

const Name = styled(Text)`
  font-weight: 600;
`

const StateText = styled(Text)`
  margin-top: 0.375rem;
  color: ${theme.color.text.light};
`

const SessionParticipant: React.FC<SessionParticipantProps> = ({
  image,
  name,
  isActive = false,
  className,
}) => {
  return (
    <Layout className={className}>
      <Avatar type="user" image={image}>
        {isActive && (
          <PerformingBadge>
            <Icon icon="MusicNote" fill="white" width={21} height={21} />
          </PerformingBadge>
        )}
      </Avatar>

      <Meta>
        <Name>{name}</Name>
        {isActive && <StateText>Performing</StateText>}
      </Meta>
    </Layout>
  )
}

export default SessionParticipant
