import React from 'react'
import { FieldError } from 'react-hook-form/dist/types'
import styled from 'styled-components'

import { Error, Label } from '../Typography'

interface InputWrapperProps {
  label?: string
  hasError?: FieldError | undefined
  errorMessage?: string | undefined
  children?: React.ReactNode
  className?: any
}

const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  height: auto;
`

const StyledLabel = styled(Label)`
  padding-left: 1.25rem;
`

const ErrorMessage = styled(Error)`
  margin-top: 0.625rem;
  padding-left: 1.25rem;
`

const InputWrapper: React.FC<InputWrapperProps> = ({
  label,
  hasError,
  errorMessage,
  children,
  className,
}) => {
  return (
    <Layout className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {children}
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Layout>
  )
}

export default InputWrapper
