import React from 'react'
import styled from 'styled-components'

import theme from 'ui/theme'

interface PanelContentProps {
  fullWidth?: boolean
  hasFooter?: boolean
  children?: React.ReactNode
  className?: any
  style?: any
}

const Layout = styled.section`
  grid-area: ${theme.panel.layout.content};
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: ${({ fullWidth }: PanelContentProps) =>
    fullWidth ? 0 : '1.5625rem'};
  padding-bottom: ${({ hasFooter }: PanelContentProps) =>
    hasFooter ? theme.panel.footer.height : 0};
  padding-left: ${({ fullWidth }: PanelContentProps) =>
    fullWidth ? 0 : '1.5625rem'};
  overflow: scroll;
`

const PanelContent: React.FC<PanelContentProps> = ({
  fullWidth = false,
  hasFooter = true,
  children,
  className,
  style,
}) => {
  return (
    <Layout
      fullWidth={fullWidth}
      hasFooter={hasFooter}
      className={className}
      style={style}
    >
      {children}
    </Layout>
  )
}

export default PanelContent
