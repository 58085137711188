import React from 'react'

import {
  AuthChooseType,
  AuthContent,
  AuthInstructions,
  AuthLogo,
  AuthPanel,
  AuthScreenContainer,
  AuthToggleButton,
  Instructions,
} from 'ui/components'
import theme from 'ui/theme'

import BGImg from 'ui/assets/image/qrs_bg_device-gray.jpg'

import { AuthCreateAccountForm } from './forms'

import { useAuth } from 'context/AuthenticationContext'

const AuthCreateAccountScreen: React.FC = () => {
  const { isAuthenticating } = useAuth()

  return (
    <AuthScreenContainer bgImage={BGImg}>
      <AuthContent />

      <AuthPanel>
        <AuthLogo />
        <AuthInstructions>
          Create a new account for {theme.appName}.
        </AuthInstructions>

        <AuthCreateAccountForm />

        <AuthChooseType>
          <Instructions>I already have an account.</Instructions>
          <AuthToggleButton label="Log in" to="/" disabled={isAuthenticating} />
        </AuthChooseType>
      </AuthPanel>
    </AuthScreenContainer>
  )
}

export default AuthCreateAccountScreen
