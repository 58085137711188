import React, { useState } from 'react'
import styled from 'styled-components'

import {
  H1,
  H3,
  Header,
  ProfileAvatar,
  ScreenContainer,
  ScreenContent,
  TabNav,
  TabNavItem,
} from 'ui/components'
import theme from 'ui/theme'

import {
  ProfileActivity,
  ProfileDevices,
  ProfileFiles,
  ProfileSettings,
} from './Tabs'

import { useAuth } from 'context/AuthenticationContext'

const StyledScreenContainer = styled(ScreenContainer)`
  grid-gap: 0 0;
`

const StyledScreenContent = styled(ScreenContent)`
  justify-content: flex-start;
  margin-bottom: 0;
  padding: 0 0;
`

const UserHeader = styled.header`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 3.125rem;
  padding: 0 3.125rem;
`

const ProfileUser = styled.section`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;
  }

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
`

const UserDetails = styled.div`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    text-align: left;
  }

  text-align: center;
`

const Avatar = styled(ProfileAvatar)`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    margin-right: 1.5625rem;
    margin-bottom: 0;
  }

  margin-bottom: 1.25rem;
`

const UserName = styled(H1)`
  line-height: 100%;
`

const Detail = styled(H3)`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    margin-top: 0.9375rem;
  }

  margin-top: 0.625rem;
  font-weight: 400;
  color: ${theme.color.white};
  opacity: 0.5;
`

const StyledTabNav = styled(TabNav)`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    width: auto;
    margin-top: 0;
  }

  width: 100%;
  margin-top: 1.5625rem;
`

const StyledTabNavItem = styled(TabNavItem)`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    width: 9.375rem;
  }

  width: 50%;
`

const ProfileStage = styled.section`
  @media (min-width: ${theme.ui.breakpoint.large}) {
    padding: 3.125rem 3.125rem 0 3.125rem;
  }

  width: 100%;
  min-height: 80vh;
  height: 100%;
  padding: 3.125rem 1.5rem 0 1.5rem;
  border-top-right-radius: ${theme.ui.radius / 16}rem;
  border-top-left-radius: ${theme.ui.radius / 16}rem;
  background-color: ${theme.color.white};
  box-shadow: 0px -0.75rem 0.75rem rgba(0, 0, 0, 0.25);
`

type Tabs = 'activity' | 'files' | 'devices' | 'settings'

const ProfileScreen: React.FC = () => {
  const { fullName } = useAuth()
  const [activeTab, setActiveTab] = useState<Tabs>('activity')

  return (
    <StyledScreenContainer>
      <Header />

      <StyledScreenContent>
        <UserHeader>
          <ProfileUser>
            <Avatar size="large" />

            <UserDetails>
              <UserName>{fullName}</UserName>
              <Detail>
                {activeTab === 'activity' && '2 Posts'}
                {activeTab === 'files' && '6 Sessions Recorded'}
                {activeTab === 'devices' && '2 Registered Devices'}
                {activeTab === 'settings' && 'Member since 12/24/2019'}
              </Detail>
            </UserDetails>
          </ProfileUser>

          <StyledTabNav>
            <StyledTabNavItem
              label="Activity"
              isActive={activeTab === 'activity'}
              onClick={() => setActiveTab('activity')}
            />
            <StyledTabNavItem
              label="Files"
              isActive={activeTab === 'files'}
              onClick={() => setActiveTab('files')}
            />
            <StyledTabNavItem
              label="Devices"
              isActive={activeTab === 'devices'}
              onClick={() => setActiveTab('devices')}
            />
            <StyledTabNavItem
              label="Settings"
              isActive={activeTab === 'settings'}
              onClick={() => setActiveTab('settings')}
            />
          </StyledTabNav>
        </UserHeader>

        <ProfileStage>
          {activeTab === 'activity' && <ProfileActivity />}
          {activeTab === 'files' && <ProfileFiles />}
          {activeTab === 'devices' && <ProfileDevices />}
          {activeTab === 'settings' && <ProfileSettings />}
        </ProfileStage>
      </StyledScreenContent>
    </StyledScreenContainer>
  )
}

export default ProfileScreen
