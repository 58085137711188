import styled from 'styled-components'

const ProfileContentContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-gap: 3.125rem;
  width: 100%;
  height: auto;
`

export default ProfileContentContainer
